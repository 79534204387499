import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Heading,
    VStack, Input, InputGroup, InputLeftAddon, useToast
} from '@chakra-ui/react';
import axios from "axios";

export default function AppModal(props) {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");

    const handleSend = async () => {
        console.log(props.septik, name, phone);

        if (name.length < 2 || phone.length < 10) toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        }); else {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            onClose();
            await axios.post('https://api.twodev.cc/helper/septiki', {
                city: props.city.text ? props.city.text : props.city,
                info: `Главная страница / септики; септик: ${props.septik}`,
                name: name,
                phone: phone
            });
        };
    };

    return <>
        <Button colorScheme='yellow' color='white' onClick={onOpen}>КУПИТЬ СЕПТИК</Button>

        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='slideInBottom'>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Оформление</ModalHeader>
                <ModalCloseButton/>
                <ModalBody bgColor="gray.100">
                    <VStack p="30px" spacing="20px">
                        <Heading textAlign="center">{props.septik || "none"}</Heading>
                        <VStack>
                            <Input onChange={event => setName(event.target.value)} placeholder='Как к Вам обращаться?' color='black' bg='white' />
                            <InputGroup>
                                <InputLeftAddon children='+7'/>
                                <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' bg='white' color='black'/>
                            </InputGroup>
                        </VStack>
                    </VStack>
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button colorScheme='orange' onClick={async () => { await handleSend(); }} w="80%">
                        Оформить заказ
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}