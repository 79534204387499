import {
    Box,
    Center,
    Image,
    Text,
    SimpleGrid,
    GridItem,
    UnorderedList,
    ListItem
} from "@chakra-ui/react";
import AppModal from "../AppModal";

export default function SeptikEcstra(props) {
    return <Center>
        <Box>
            <Center>
                <Text w='100%' textAlign='left' fontFamily='Twenty' fontSize={20} mt='80px' >Серия Экстра</Text>
            </Center>
            <Center >
                <Text w='100%' textAlign='left' fontFamily='Hero' fontSize={18} >
                    Септики серии Экстра оборудованы дренажным насосом с внутренним поплавком, для участков с высоким уровнем грунтовых вод.
                </Text>
            </Center>

            <Center>
                <Box mt='40px' w='90%' >
                    <SimpleGrid columns={1} gap='20px' >
                        <GridItem ml='0px' >
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/ecstra1.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px' >
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 0,9 Экстра</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 1600 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 1430х930х1600</ListItem>
                                        <ListItem>Общий объём: 900 л</ListItem>
                                        <ListItem>Вес 60 кг</ListItem>
                                        <ListItem>Кол-во пользователей 1-2 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >21 400 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 0,9 Экстра" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/ecstra3.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px'>
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 2.0 Экстра</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 2000 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 2000х1000х2000</ListItem>
                                        <ListItem>Общий объём: 2000 л</ListItem>
                                        <ListItem>Вес 125 кг</ListItem>
                                        <ListItem>Кол-во пользователей 3-4 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >35 300 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 2.0 Экстра" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/ecstra4.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px'>
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 2.5 Экстра</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 2100 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 1750х1450х2100</ListItem>
                                        <ListItem>Общий объём: 2500 л</ListItem>
                                        <ListItem>Вес 145 кг</ListItem>
                                        <ListItem>Кол-во пользователей 4-5 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >41 700 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 2.5 Экстра" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/ecstra2.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px'>
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 3.0 Экстра</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 2100 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 2130х1360х2100</ListItem>
                                        <ListItem>Общий объём: 3000 л</ListItem>
                                        <ListItem>Вес 160 кг</ListItem>
                                        <ListItem>Кол-во пользователей 5-6 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >48 300 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 3.0 Экстра" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                    </SimpleGrid>

                </Box>
            </Center>
        </Box>
    </Center>
}