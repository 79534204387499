import {
    Box,
    Center,
    HStack,
    VStack,
    Image,
    Text,
    Icon,
    SimpleGrid,
    GridItem
} from "@chakra-ui/react";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Footer() {
    function getLink(target) {
        const first = window.location.pathname.slice(1).split("/")[0];
        return ["", "dw", "price", "septik", "services", "contact"].includes(first) ? `${target}` : `/${first}${target}`;
    }

    return <VStack w='100%' bg='#323232' pt='75px' spacing="50px">
        <Box>
            <Image src={require('../../../img/logo.png')} />
            <Text color='white' fontSize={14} my="20px">Спасибо за то, что доверяете нам.<br /> Строительством септиков и копкой<br /> колодцев мы занимаемся уже более 8 лет ...</Text>
            <HStack>
                <a href="tg://resolve?domain=" className='big'><Icon as={FaTelegram} w='35px' h='35px' color='white' /></a>
                <a href="https://wa.me/" className='big'><Icon as={FaWhatsapp} w='35px' h='35px' color='white' /></a>
                <a href="https://viber.click/" className='big'><Icon as={FaViber} w='35px' h='35px' color='white' /></a>
                <a href="https://viber.click/" className='big'><Icon as={AiFillMail} w='35px' h='35px' color='white' /></a>
            </HStack>
        </Box>
        <VStack>
            <Text color='white' fontFamily='Twenty' fontSize={22}>Время работы</Text>
            <SimpleGrid columns={2} mt='10px'>
                <GridItem><Text color='white'>Понедельник</Text></GridItem>
                <GridItem><Text color='white'>08:00 AM - 20:00 PM</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>Вторник</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>08:00 AM - 20:00 PM</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>Среда</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>08:00 AM - 20:00 PM</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>Четверг</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>08:00 AM - 20:00 PM</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>Пятница</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>08:00 AM - 20:00 PM</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>Суббота</Text></GridItem>
                <GridItem mt='10px'><Text color='white'>Выходной</Text></GridItem>
            </SimpleGrid>
        </VStack>
        <VStack>
            <Text color='white' fontFamily='Twenty' fontSize={22}>Быстрые ссылки</Text>
            <Text color='white'>Колодцы</Text>
            <Text color='white'>Септики</Text>
            <Text color='white'>Перечень услуг</Text>
            <Text color='white'>Актуальные цены</Text>
        </VStack>
        <HStack spacing="0px">
            <Text color='gray.400' fontFamily="Hero" align="right" fontWeight="bold">Developed by</Text>
            <Image src={require('../../../img/AIYlogo.png')} />
        </HStack>
    </VStack>
}