import { Text, Box, Heading, Center, Image } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export default function Article5(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return <Box w='100%' mt='150px'  >
        <Center>
            <Box w='85%' minW='1000px' maxW='1200px' >
                <Heading fontFamily='Twenty' fontSize="30px" >Чистка колодцев от 2000 руб в {typeof props.city === "string" ? props.city : props.city.text2}</Heading>

                <Text fontFamily='Hero' mt='20px' fontSize='20px' w='80%' >
                    При постоянном пользовании колодца просто необходимо проводить его техническое обслуживание и ремонт, ведь от чистоты воды зависит здоровье вас и вашей семьи.<br /><br />

                    Когда стоит задуматься об осмотре колодца? Об этом должно сказать изменение качества воды:<br /><br />

                    – мутность;<br />
                    – изменение цвета (иди вообще его появление);<br />
                    – неприятный запах и вкус;<br />
                    – появление взвесей и ржавчины;<br />
                    – образование радужной пленки на поверхности.<br />

                    К тому же стоит периодически сдавать воду на лабораторный анализ для проверки на бактериальные загрязнения или химические примеси.
                </Text>

                <Text fontFamily='Twenty' fontSize={30} mt='40px' >Немного о технике безопасности</Text>
                <Text fontFamily='Hero' fontSize='17px' w='80%'  >
                    Если вы ремонтируете колодец самостоятельно, необходимо соблюдать несколько правил. Во-первых, желательно иметь помощника или человека, который бы смог вовремя понять, что у спустившегося вниз человека что-то не в порядке. Во-вторых, необходимо убедиться достаточно ли в шахте кислорода. Вряд ли у вас под рукой найдется шахтерская канарейка, но вполне достаточно будет опустить ко дну свечу – ровное яркое пламя даст вам понять о безопасности нахождения внизу. При этом помните, что людям с сердечно-сосудистыми заболеваниями ниже двух метров в шахте лучше не задерживаться. В-третьих, возьмите страховку. Страховочный трос – лучшая гарантия уверенности в завтрашнем дне при ремонте колодца.<br /><br />

                    Специалисты рекомендуют дополнительно обратить внимание на качество лестницы (безопасная и крепкая) и амуницию (высокие резиновые сапоги с дополнительным утеплением и теплая куртка)
                </Text>
                <Text fontFamily='Twenty' fontSize='30px' mt='40px' >Механическая очистка</Text>
                <Text fontFamily='Hero' fontSize='17px' w='80%' >
                    Для нее понадобятся кроме всего прочего насос, ведра и скребки. После откачки из шахты воды насосом устанавливают лестницу, а затем долго и настойчиво вручную чистят скребками стены колодца. Далее необходимо осмотреть дно и по результатам промыть или заменить фильтрующий слой. Обязательно стоит осмотреться в поисках ремонта: образовавшихся щелей, протекающих зазоров. По окончании механических работ следует дезинфицировать стены и дно раствором марганцовки с обязательной откачкой дезинфицирующего раствора.
                </Text>
                <Text fontFamily='Twenty' fontSize='30px' mt='40px' w='80%' >Герметизацию щелей и зазоров между кольцами осуществляют следующим образом:</Text>
                <Text fontFamily='Hero' fontSize='17px' w='80%' >
                    – штробление устаревшего раствора, очистка стыков колец<br />
                    – промывка торцов колец аппаратом высокого давления<br />
                    – герметизация<br />
                    – гидроизоляция.<br /><br />

                    Чем не рекомендуется заделывать зазоры и герметизировать швы – цементно-песчаными смесями маркой М-300, М-500 и жидким стеклом. Цемент, не предназначенный для гидросооружений, будет просто напросто вымываться, обновляя заделанную щель и ухудшая качество питьевой воды. Жидкое стекло (силикатный клей) меж тем доказано вреден для человеческого организма
                </Text>
                <Center><Image src={require('../../../../img/chistka_1.jpg')} mb='30px' /></Center>
            </Box>
        </Center>
    </Box>
}