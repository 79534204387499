import Navbar from "./components/Laptop/Navbar/index";
import ScrollToTop from "react-scroll-to-top";
import React from "react";
import Footer from "./components/Laptop/Footer";
import Main from "./components/Laptop/Page/main";
import Price from './components/Laptop/Page/price';
import DiggingWells from './components/Laptop/Page/diggingWells';
import Septik from './components/Laptop/Page/septik';
import Services from './components/Laptop/Page/servicesPage';
import Contact from './components/Laptop/Page/contact';
import Article1 from "./components/Laptop/Infos/child/article1";
import Article2 from "./components/Laptop/Infos/child/article2";
import Article3 from "./components/Laptop/Infos/child/article3";
import Article4 from "./components/Laptop/Infos/child/article4";
import Article5 from "./components/Laptop/Infos/child/article5";
import Article6 from "./components/Laptop/Infos/child/article6";

export default function LaptopApp(props) {
    return <>
        <Navbar city={props.city} />
        
        {
            props.page === "dw"
                ? <DiggingWells city={props.city} />
                : props.page === "contact"
                    ? <Contact city={props.city} />
                    : props.page === "price"
                        ? <Price city={props.city} />
                        : props.page === "septik"
                            ? <Septik city={props.city} />
                            : props.page === "services"
                                ? <Services city={props.city} />
                                : props.page === "a1"
                                    ? <Article1 city={props.city} />
                                    : props.page === "a2"
                                        ? <Article2 city={props.city} />
                                        : props.page === "a3"
                                            ? <Article3 city={props.city} />
                                            : props.page === "a4"
                                                ? <Article4 city={props.city} />
                                                : props.page === "a5"
                                                    ? <Article5 city={props.city} />
                                                    : props.page === "a6"
                                                        ? <Article6 city={props.city} />
                : <Main city={props.city} />
        }

        <Footer />
        <ScrollToTop smooth style={{ paddingLeft: '5px' }} />

    </>
}