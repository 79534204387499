import {
    Text,
    Icon,
    HStack,
    Box,
    Heading,
    ListItem,
    OrderedList,
    VStack
} from "@chakra-ui/react";
import FormApplication from "../Form";
import { PhoneIcon } from "@chakra-ui/icons";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import { AiOutlineMessage } from "react-icons/ai";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { IoPricetags } from "react-icons/io5";

export default function Contact(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <VStack pt="5vh" align="center" spacing="4vh">
        <HStack>
            <Icon as={IoPricetags} color='gold' w='35px' h='35px' />
            <Heading fontFamily='Hero' fontSize={22}>Контакты г. {typeof props.city === "string" ? props.city : props.city.text}</Heading>
        </HStack>

        <Box px="10vw">
            <Text fontFamily='Twenty' fontSize='18pt' align="center">С нами будет выгодно!</Text>
            <OrderedList fontSize='13pt'>
                <ListItem>Работаем по договору, в котором обговариваем все моменты.</ListItem>
                <ListItem>Не берем предоплату.</ListItem>
                <ListItem>Бесплатно консультируем и приезжаем на гарантийный ремонт.</ListItem>
                <ListItem>Работаем круглогодично.</ListItem>
                <ListItem>Сложные грунты? разберемся!</ListItem>
                <ListItem>Колодцы копаем только вручную.</ListItem>
                <ListItem>Используем обезжиренные кольца.</ListItem>
                <ListItem>Септики и выгребные ямы без запаха.</ListItem>
            </OrderedList>
        </Box>
        <Box w='100%' h='500px' >
            <FormApplication city={props.city} />
        </Box>
    </VStack>
}