import {
    Text,
    Image,
    Heading,
    VStack
} from "@chakra-ui/react";

export default function SeptikBlock() {
    return <VStack w="100%">
        <Heading textAlign='center' fontSize='27pt'>Что такое септик?</Heading>
        <VStack spacing='40px'>
            <Text fontSize={18} mt='50px' align="left">
                Септик – это более современный вид канализации частного дома, промежуточный вариант между выгребной ямой (где отходы просто накапливаются) и локальной станцией очистки (в функции которой входит не только накопление, но и переработка отходов).
                <br /><br />
                Отстаивание, применение специализированных аэробных и анаэробных бактерий, методы естественной и принудительной доочистки – все это позволяет перерабатывать стоки, получая на выходе очищенную до 80-90% воду. Необходимость в ассенизаторских услугах в случае использования септика
                снижается до одного раза в один-два года.
                <br /><br />
                Такая система существенно дороже выгребной ямы и занимает гораздо больше места, к тому же не на всех участках можно установить септик – при высоких грунтовых водах емкости будут просто напросто выдавливаться из мест установки.
            </Text>
            <Image src={require('../../../img/septik-block.jpeg')} w='400px' />
        </VStack>
    </VStack>
}