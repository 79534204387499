import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LaptopApp from './LaptopApp';
import MobileApp from './MobileApp';
import { Helmet } from "react-helmet";
import "./fonts/ofont.ru_Garet.ttf";
import cities from "./cities.json";

const oblasti = Object.entries(cities).filter(city => city);
const goroda = Object.values(cities).filter(city => city.cities).map(city => Object.entries(city.cities));
const routes = ["dw", "contact", "price", "septik", "services", "a1", "a2", "a3", "a4", "a5", "a6"];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<ChakraProvider theme={extendTheme({ fonts: { body: `"Helvetica Neue", Helvetica, Arial, sans-serif`, heading: ``, text: `` } })}>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={
                <>
                    <Helmet>
                        <title>Септики и колодцы по выгодным ценам в Москве</title>
                        <meta name="description" content="Септики и колодцы по выгодным ценам в Москве" />
                        <meta name="keywords" content="септики, колодцы, москва" />
                    </Helmet>
                    {window.innerWidth > 1000 ? <LaptopApp page="main" city={{ text: "Москва", text2: "Москве" }} /> : <MobileApp page="main" city={{ text: "Москва", text2: "Москве" }} />}
                </>
            } />
            {routes.map(r => <Route path={`/${r}`} element={
                <>
                    <Helmet>
                        <title>Септики и колодцы по выгодным ценам в Москве</title>
                        <meta name="description" content="Септики и колодцы по выгодным ценам в Москве" />
                        <meta name="keywords" content="септики, колодцы, москва" />
                    </Helmet>
                    {window.innerWidth > 1000 ? <LaptopApp page={r} city={{ text: "Москва", text2: "Москве" }} /> : <MobileApp page={r} city={{ text: "Москва", text2: "Москве" }} />}
                </>
            } />)}



            {oblasti.map(city => <Route path={`/${city[0]}`} element={
                <>
                    <Helmet>
                        <title>Септики и колодцы по выгодным ценам в {typeof city[1] === "string" ? city[1] : city[1].text2}</title>
                        <meta name="description" content={`Септики и колодцы по выгодным ценам в ${typeof city[1] === "string" ? city[1] : city[1].text2}`} />
                        <meta name="keywords" content={`септики, колодцы, ${typeof city[1] === "string" ? city[1] : city[1].text}`} />
                    </Helmet>
                    {window.innerWidth > 1000 ? <LaptopApp page="main" city={city[1]} /> : <MobileApp page="main" city={city[1]} />}
                </>
            } />)}
            {routes.map(r =>
                oblasti.map(city => <Route path={`/${city[0]}/${r}`} element={
                    <>
                        <Helmet>
                            <title>Септики и колодцы по выгодным ценам в {typeof city[1] === "string" ? city[1] : city[1].text2}</title>
                            <meta name="description" content={"Септики и колодцы по выгодным ценам в " + typeof city[1] === "string" ? city[1] : city[1].text2} />
                            <meta name="keywords" content={"септики, колодцы, " + typeof city[1] === "string" ? city[1] : city[1].text} />
                        </Helmet>
                        {window.innerWidth > 1000 ? <LaptopApp page={r} city={city[1]} /> : <MobileApp page={r} city={city[1]} />}
                    </>
                } />)
            )}



            {goroda.map(c => c.map(city => <Route path={`/${city[0]}`} element={
                <>
                    <Helmet>
                        <title>Септики и колодцы по выгодным ценам в {typeof city[1] === "string" ? city[1] : city[1].text2}</title>
                        <meta name="description" content={"Септики и колодцы по выгодным ценам в " + typeof city[1] === "string" ? city[1] : city[1].text2} />
                        <meta name="keywords" content={"септики, колодцы, " + typeof city[1] === "string" ? city[1] : city[1].text} />
                    </Helmet>
                    {window.innerWidth > 1000 ? <LaptopApp page="main" city={city[1]} /> : <MobileApp page="main" city={city[1]} />}
                </>
            } />))}
            {routes.map(r =>
                goroda.map(c => c.map(city => <Route path={`/${city[0]}/${r}`} element={
                    <>
                        <Helmet>
                            <title>Септики и колодцы по выгодным ценам в {typeof city[1] === "string" ? city[1] : city[1].text2}</title>
                            <meta name="description" content={"Септики и колодцы по выгодным ценам в " + typeof city[1] === "string" ? city[1] : city[1].text2} />
                            <meta name="keywords" content={"септики, колодцы, " + typeof city[1] === "string" ? city[1] : city[1].text} />
                        </Helmet>
                        {window.innerWidth > 1000 ? <LaptopApp page={r} city={city[1]} /> : <MobileApp page={r} city={city[1]} />}
                    </>
                } />))
            )}
        </Routes>
    </BrowserRouter>
</ChakraProvider>);