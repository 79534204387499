import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";

export default function Services(props) {
    return <Center>
        <Box w='95%' mt='50px'>
            <Carousel interval={5000} infiniteLoop={true} showStatus={false} showThumbs={false} showIndicators={false}>
                <HStack h='355px' pl='15px'>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/well.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Копка и строительство...</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/contact.jpeg')} h='193.6px' />
                        <VStack>
                            <Text fontWeight={500} fontSize={21} mt='5px'>Наши контакты в {typeof props.city === "string" ? props.city : props.city.text2}...</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/price.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Прайс в {typeof props.city === "string" ? props.city : props.city.text2}</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                </HStack>
                <HStack h='355px' pl='15px'>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/septik.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Септики и выгребные ямы</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/spisok.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Список наших услуг</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/well.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Копка и строительство...</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                </HStack>
                <HStack h='355px' pl='15px'>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/contact.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Наши контакты в {typeof props.city === "string" ? props.city : props.city.text2}...</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/price.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Прайс для {typeof props.city === "string" ? props.city : props.city.text}</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                    <Box w='100%' h='300px' className="services" transition='1s' >
                        <Image src={require('../../../img/septik.jpeg')}/>
                        <VStack>
                            <Text fontWeight={500} fontSize={22} mt='5px' >Септики и выгребные ямы</Text>
                            <Text color='gold' fontSize={17} >Подробнее &#62;&#62;</Text>
                        </VStack>
                    </Box>
                </HStack>
            </Carousel>
        </Box>
    </Center>
}