import React, { useState } from 'react';
import { Text, Heading, VStack, Box, HStack, Button, SimpleGrid, Flex, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import img1 from "../../../img/yama_1.jpg";
import img2 from "../../../img/kolodci_1.jpg";
import img3 from "../../../img/kolodec_pod_poliv.jpg";
import img4 from "../../../img/kolodec-chistka.jpg";
import img5 from "../../../img/chistka_1.jpg";
import img6 from "../../../img/mashinabur_4_480x400.jpg";

export default function Infos(props) {

    const [selected, setSelected] = useState(0);

    let infos = [
        {
            bg: img1,
            type: [0, 2],
            text: "Выгребная яма/Септик",
            link: "a1"
        },
        {
            bg: img2,
            type: [0, 1],
            text: "Колодцы для питьевой воды",
            link: "a2"
        },
        {
            bg: img3,
            type: [0, 1],
            text: "Поливные и питьевые колодцы",
            link: "a3"
        },
        {
            bg: img4,
            type: [0, 1],
            text: "Ремонт питьевых колодцев",
            link: "a4"
        },
        {
            bg: img5,
            type: [0, 1],
            text: "Чистка колодцев от 2000 руб",
            link: "a5"
        },
        {
            bg: img6,
            type: [0, 1],
            text: "Бурение колодцев машиной",
            link: "a6"
        }
    ];

    function reduceBlock() {
        const block = document.getElementById('block');
        block.style.height = '600px'
        block.style.transition = '0.5s';
    }

    function increaseBlock() {
        const block = document.getElementById('block');
        block.style.height = '950px';
        block.style.transition = '0.5s';
    }

    function getLink(target) {
        const first = window.location.pathname.slice(1).split("/")[0];
        return ["", "dw", "price", "septik", "services", "contact", "a1", "a2", "a3", "a4", "a5", "a6"].includes(first) ? `${target}` : `/${first}${target}`;
    }

    return <VStack w="100%" position="relative" style={{ height: "950px", transition: '0.5s' }} id='block' >
        <Box position="absolute" w="100%" h="100%" bgImage={require("../../../img/back.png")} bgSize="150%" style={{ filter: "brightness(10%)" }}></Box>
        {/* <Box bgImage={require("../../../img/back.png")} style={{ filter: "brightness(50%)" }}></Box> */}
        <VStack position="absolute" w="100%">
            <Box align="center" my="2vw">
                <Heading color="white">СТАТЬИ ПО СЕПТИКАМ И КОЛОДЦАМ</Heading>
                <br />
                <Text color="gray" fontStyle="italic">Хотите знать больше? Чем отличается септик от выгребной ямы, или колодец для питьевой и поливной воды?</Text>
            </Box>
            <Box>
                <HStack justify="center" spacing="20px" mb="24px">
                    <Button p="20px" onClick={() => { setSelected(0); increaseBlock() }}
                        colorScheme={selected === 0 ? 'yellow' : 'orange'}
                        variant={selected === 0 ? 'outline' : 'solid'}>ВСЕ</Button>
                    <Button p="20px" onClick={() => { setSelected(1); increaseBlock() }}
                        colorScheme={selected === 1 ? 'yellow' : 'orange'}
                        variant={selected === 1 ? 'outline' : 'solid'}>Колодцы</Button>
                    <Button p="20px" onClick={() => { setSelected(2); reduceBlock() }}
                        colorScheme={selected === 2 ? 'yellow' : 'orange'}
                        variant={selected === 2 ? 'outline' : 'solid'}
                        id='but'>Септики</Button>
                </HStack>
                <SimpleGrid columns={3} spacing='40px' h='max-content'  >
                    {infos.filter(x => x.type.includes(selected)).map((x, i) => {
                        return <LinkBox as='article'>
                            <Link to={x.link} >
                                <Flex w='400px' h='300px' bgImage={x.bg} bgSize="cover" justify="center" align="end" transition='0.5s' _hover={{ filter: "brightness(0.3)", cursor: "pointer", transition: '0.5s' }}>
                                    <Box bgColor="#323232" justify="center" align="center" w="100%" p="14px" m="10px">
                                        <LinkOverlay href={getLink(`/a${i+1}`)}><Text color="white">{x.text.toUpperCase()}</Text></LinkOverlay>
                                    </Box>
                                </Flex>
                            </Link>
                        </LinkBox>
                    })}
                </SimpleGrid>
            </Box>
        </VStack>
    </VStack>
}