import {
    Text,
    Icon,
    HStack,
    Box,
    Center,
    ListItem,
    OrderedList,
    VStack
} from "@chakra-ui/react";
import FormApplication from "../Form";
import { PhoneIcon } from "@chakra-ui/icons";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import { AiOutlineMessage } from "react-icons/ai";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { IoPricetags } from "react-icons/io5";
import Phone from "../../Phone";

export default function Contact(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <Box>
        <Center>
            <Box w='90%' mt='40px' p='20px' mb='50px'>
                <Text float='left' textAlign='center' fontFamily='Twenty' fontSize='23pt'>Наша контактная информация</Text>
                <Box w='100%' border='solid 0.5px gray' mt='60px' opacity='0.5' ></Box>
                <HStack mt='20px' >
                    <Icon as={IoPricetags} color='gold' w='35px' h='35px' />
                    <Text fontFamily='Hero' mt='15px' fontSize={22} >Контакты г. {typeof props.city === "string" ? props.city : props.city.text}</Text>
                </HStack>

                <Text fontFamily='Twenty' fontSize='18pt' ml='50px' mt='30px' >С нами будет выгодно:</Text>
                <OrderedList ml='100px' fontSize='13pt' mt='10px' >
                    <ListItem>Работаем по договору, в котором обговариваем все моменты.</ListItem>
                    <ListItem>Не берем предоплату.</ListItem>
                    <ListItem>Бесплатно консультируем и приезжаем на гарантийный ремонт.</ListItem>
                    <ListItem>Работаем круглогодично.</ListItem>
                    <ListItem>Сложные грунты? разберемся!</ListItem>
                    <ListItem>Колодцы копаем только вручную.</ListItem>
                    <ListItem>Используем обезжиренные кольца.</ListItem>
                    <ListItem>Септики и выгребные ямы без запаха.</ListItem>
                </OrderedList>
                <Text fontFamily='Twenty' fontSize={40} textAlign='center' mt='50px'  >Наши Контакты</Text>
                <Center>
                    <Box w='1000px' h='350px' mt='30px' className="boxShadow" mb='100px' p='25px'>
                        <Center>
                            <HStack gap='30px' >
                                <Box w='300px' h='300px'>
                                    <VStack mt='35%'>
                                        <Icon as={PhoneIcon} w='30px' h='30px' color='gold' />
                                        <Text fontSize={20} fontFamily='Hero'>Позвоните нам</Text>
                                        <Phone city={props.city} page='contact' />
                                    </VStack>
                                </Box>
                                <Box h='190px' mt='5px' borderRight='solid 1.5px gray' opacity={0.5} ></Box>
                                <Box w='300px' h='300px'>
                                    <VStack mt='30.5%'>
                                        <Icon as={AiOutlineMessage} w='40px' h='40px' color='gold' />
                                        <Text fontSize={20} fontFamily='Hero'>Напишите нам</Text>
                                        <HStack>
                                            <a href="tg://resolve?domain=" className='big' ><Icon as={FaTelegram} w='35px' h='35px' /></a>
                                            <a href="https://wa.me/" className='big' ><Icon as={FaWhatsapp} w='35px' h='35px' /></a>
                                            <a href="https://viber.click/" className='big' ><Icon as={FaViber} w='35px' h='35px' /></a>
                                        </HStack>
                                    </VStack>
                                </Box>
                            </HStack>
                        </Center>
                    </Box>
                </Center>
            </Box>
        </Center>
        <Box w='100%' h='500px' >
            <Center>
                <FormApplication city={props.city} />
            </Center>
        </Box>
    </Box>
}