import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
} from "@chakra-ui/react";

export default function UsefulInformation() {
    return <>
        <Accordion allowMultiple w='550px' h='max-content' >
            <AccordionItem w='550px' bg='white' className="accordion"  transition="1s" mb='15px' py='10px' borderRadius={10}  >
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left' fontSize={16} fontFamily='Hero' >
                            Вам предлагают пробурить разведовательную скважину?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontFamily='Hero'>
                    Очень часто многие горекопатели колодцев под питьевую воду предлагают до начала работ пробурить разведовотельную скважину, ссылаясь на то, что рамка или лоза не даёт 100% гарантии. Знайте, это обман и лишняя трата средств. Разведовательная скважина нискоинформативна и, как правило, в реальном колодце вода ниже или вообще отсутствует.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem w='550px' bg='white' mb='15px' py='10px' borderRadius={10} className="accordion" transition="1s">
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left' fontFamily='Hero'> 
                            Колодец за один день?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontFamily='Hero'>
                    При ручной копке такие сроки просто не реальны и выдержать их невозможно. Чем глубже водоносный слой, тем плотнее почва и копать её все сложнее + разные типы грунтов, которые могут существенно отличаться в радиусе 20 метров. Попадая на камни скорость копки существенно замедляется, а горекопатели, которые пообщели "выкопать за один день" вынуждены будут свернуть работы или установить кольца на сухую, мол подождите 5-6 дней и вода будет.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem w='550px' bg='white' mb='15px' py='10px' borderRadius={10} className="accordion" transition="1s">
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left' fontFamily='Hero'>
                            Четко сформулированная договоренность!
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontFamily='Hero'>
                    Единственный правильный способ избежать ошибок и получить желаемое от вложения - это договор с "колодезниками" на копку и обустройство скважины колодца, стоимость каждого кольца. Также следует обговорить наличие инструмента и буровой вышки, наличие воды и её уровень (2 кольца, ТРИ). В таком случае если даже рабочие прокапают 6, 12, 24 метра и воды нет или будет плывут, булыжники - это их проблемы. Либо копайте, долбите, или уезжайте без денег! Ну, забирайте свои опущенные кольца и уезжайте или ройте глубже – только так.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem w='550px' bg='white' py='10px' borderRadius={10} className="accordion" transition="1s">
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left' fontFamily='Hero'>
                            Просят предоплату?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontFamily='Hero'>
                    Еще одна уловка рукожопых. Не следует давать и копейки пока работа не будет закончена и не будет воды и её уровня, который вы обговорили в договоре. Деньги - лучший мотиватор для исполнителя!
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    </>
}