import { Text, Box, Heading, Center, Image } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export default function Article6(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return <Box w='100%' mt='150px'  >
        <Center>
            <Box w='85%' minW='1000px' maxW='1200px' >
                <Heading fontFamily='Twenty' fontSize="30px" >Бурение колодцев машиной в {typeof props.city === "string" ? props.city : props.city.text2}</Heading>

                <Text fontFamily='Hero' mt='20px' fontSize='20px' w='80%' >
                    Кроме самого распространенного ручного способа копки колодцев под воду, существует и другой - механизированный. Машинная установка для бурения колодцев под кольца расположена на самоходных машинах повышенной проходимости и представляет из себя буровую установку со специальной насадкой, диаметр которой чуть больше одного метра.<br /><br />

                    Копка колодцев машиной под кольца осуществляется только на мягких грунтах. Глубина возможного бурения зависит от типа механизированной буровой установки. Шнековая буровая установка используется для неглубоких колодцев и легких (средних) грунтов, а вот роторный тип буровой установки с подачей воды под давлением. Также есть третий тип машинной буровой установки под бурение колодцев на воду - это установка с коронкой. Установка с коронкой также как и первый тип мобильных буровых установок используется только на небольшие глубины и на мягких грунтах.
                </Text>

                <Box w='80%'><Center><Image src={require('../../../../img/burenie-kolodtsev-mashinoj.jpeg')} w='600px' h='400px' mt='30px' /></Center></Box>

                <Text fontFamily='Hero' fontSize='17px' w='80%' mt='40px' >
                    Непосредственно после бурения необходимо сразу опустить в ствол шахты бетонные кольца, пока не произошло обрушение грунта. Монтаж осуществляется подъёмным краном по несколько колец за один подъём.<br /><br />

                    Основным преимуществом машинного бурения является скорость и качество ствола шахты её точная вертикальность и расчетная глубина.<br /><br />

                    Вторым неоспоримым преимуществом машинной копки колодцев под воду в {typeof props.city === "string" ? props.city : props.city.text2} является целостность водоносного слоя, его пор и протоков. Дело в том, что во время ручной копки страдает скорость, за это время происходит обрушение капилляров и протоков в водоносном слое, их закрытие. Механизированным бурением колодцы быстрее прокачиваются, быстрее оседают, а вода скорее становится чистой и, соответственно, вы получаете чистую воду гораздо быстрее. Во время ручной копки, как писали выше, происходит более массивное обрушение и закрытие пор, которые, постепенно, вымываются водой и восстанавливаются, но гораздо дольше из-за тяжести повреждений.
                </Text>
                    <Text fontFamily='Twenty' fontSize='30px' w='80%' mt='40px'  >
                        Бурение колодцев под ключ с материалами в {typeof props.city === "string" ? props.city : props.city.text2}.
                    </Text>
                    <Text fontFamily='Hero' mt='15px' mb='40px' w='80%' >
                        Наши исполнители имеют не один десяток положительных отзывов и опыт в данном деле не менее 8 лет. Со всеми подрядчиками в {typeof props.city === "string" ? props.city : props.city.text2} мы работаем на регулярной основе и поэтому гарантируем соблюдение срока и качества услуг.
                    </Text>
            </Box>
        </Center>
    </Box>
}