import {
    Box,
    Center,
    Image,
    Text,
    SimpleGrid,
    GridItem,
    UnorderedList,
    ListItem
} from "@chakra-ui/react";
import AppModal from "../AppModal";

export default function SeptikStandart(props) {
    return <Center>
        <Box>
            <Center>
                <Text w='100%' textAlign='left' fontFamily='Twenty' fontSize={20} mt='90px' >Серия Cтандарт</Text>
            </Center>
            <Center >
                <Text w='100%' textAlign='left' fontFamily='Hero' fontSize={18} >
                    Накопительные ёмкости серии Стандарт отлично подойдут для хранения питьевой воды так как сделаны из пищевого полиэтилена.
                </Text>
            </Center>
            <Center>
                <Box w='95%' mt='40px' >
                    <Center>
                        <SimpleGrid columns={1} gap='50px' >
                            <GridItem>
                                <Box w='100%' h='823px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                                    <Box h='380px' w='100%'>
                                        <Center>
                                            <Image src={require('../../../img/standart1.jpeg')} h='380px' />
                                        </Center>
                                    </Box>
                                    <Box h='60px' bg='rgb(235,235,235)' p={1}>
                                        <Text textAlign='center' fontSize='14pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 0,9 Накопительный</Text>
                                    </Box>
                                    <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                        <Center>
                                            <hr style={{
                                                color: '#000000',
                                                backgroundColor: '#000000',
                                                height: 0.6,
                                                borderColor: '#000000',
                                                width: 70,
                                                opacity: 0.4
                                            }} />
                                        </Center>
                                    </Box>
                                    <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                        <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                            <ListItem>Работает с системой фильтрации</ListItem>
                                            <ListItem>Высота емкости 1600 мм</ListItem>
                                            <ListItem>Габариты (ДхШхВ): 1430х930х1600</ListItem>
                                            <ListItem>Общий объём: 900 л</ListItem>
                                            <ListItem>Вес 55 кг</ListItem>
                                            <ListItem>Кол-во пользователей 1-2 человека</ListItem>
                                        </UnorderedList>
                                    </Box>
                                    <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                        <Text fontSize={45} fontFamily="Twenty">16 900 ₽</Text>
                                    </Box>
                                    <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                        <AppModal city={props.city} septik="Септик Биофор 0,9 Накопительный" />
                                    </Box>
                                    <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                        <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                    </Box>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box w='100%' h='773px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                                    <Box h='330px' w='100%'>
                                        <Center>
                                            <Image src={require('../../../img/standart2.jpeg')} h='330px' />
                                        </Center>
                                    </Box>
                                    <Box h='60px' bg='rgb(235,235,235)' p={1}>
                                        <Text textAlign='center' fontSize='14pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 2.5 Накопительный</Text>
                                    </Box>
                                    <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                        <Center>
                                            <hr style={{
                                                color: '#000000',
                                                backgroundColor: '#000000',
                                                height: 0.6,
                                                borderColor: '#000000',
                                                width: 70,
                                                opacity: 0.4
                                            }} />
                                        </Center>
                                    </Box>
                                    <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                        <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                            <ListItem>Работает с системой фильтрации</ListItem>
                                            <ListItem>Высота емкости 2100 мм</ListItem>
                                            <ListItem>Габариты (ДхШхВ): 1750х1450х2100</ListItem>
                                            <ListItem>Общий объём: 2500 л</ListItem>
                                            <ListItem>Вес 125 кг</ListItem>
                                            <ListItem>Кол-во пользователей 3-4 человека</ListItem>
                                        </UnorderedList>
                                    </Box>
                                    <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                        <Text fontSize={45} fontFamily="Twenty">29 500 ₽</Text>
                                    </Box>
                                    <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                        <AppModal city={props.city} septik="Септик Биофор 2.5 Накопительный" />
                                    </Box>
                                    <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                        <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                    </Box>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box w='100%' h='823px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                                    <Box h='380px' w='100%'>
                                        <Center>
                                            <Image src={require('../../../img/standart3.jpeg')} h='380px' />
                                        </Center>
                                    </Box>
                                    <Box h='60px' bg='rgb(235,235,235)' p={1}>
                                        <Text textAlign='center' fontSize='14pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 3.0 Накопительный</Text>
                                    </Box>
                                    <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                        <Center>
                                            <hr style={{
                                                color: '#000000',
                                                backgroundColor: '#000000',
                                                height: 0.6,
                                                borderColor: '#000000',
                                                width: 70,
                                                opacity: 0.4
                                            }} />
                                        </Center>
                                    </Box>
                                    <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                        <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                            <ListItem>Работает с системой фильтрации</ListItem>
                                            <ListItem>Высота емкости 2100 мм</ListItem>
                                            <ListItem>Габариты (ДхШхВ): 2130х1360х2100</ListItem>
                                            <ListItem>Общий объём: 3000 л</ListItem>
                                            <ListItem>Вес 160 кг</ListItem>
                                            <ListItem>Кол-во пользователей 4-5 человека</ListItem>
                                        </UnorderedList>
                                    </Box>
                                    <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                        <Text fontSize={45} fontFamily="Twenty">36 000 ₽</Text>
                                    </Box>
                                    <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                        <AppModal city={props.city} septik="Септик Биофор 3.0 Накопительный" />
                                    </Box>
                                    <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                        <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                    </Box>
                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Center>

                </Box>
            </Center>
        </Box>
    </Center>
}