import { Text, Box, Heading, Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export default function Article1(props) {
    
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <Box w='100%' mt='150px'  >
        <Center>
            <Box w='85%' minW='1000px' maxW='1200px' >
                <Heading fontFamily='Twenty' fontSize="30px" >Выгребная яма/Септик в {typeof props.city === "string" ? props.city : props.city.text2}</Heading>

                <Text fontFamily='Hero' mt='20px' fontSize='20px' w='80%' >
                    Одним из важнейших условий создания комфортных условий на приобретенном участке – это наличие канализации, поскольку отходы человеческой жизнедеятельности необходимо своевременно удалять, причем это нужно делать как можно безопаснее для окружающей среды.
                    <br /><br />
                    Современные методы строительства предлагают застройщику широкий выбор разнообразных решений: от выгребной ямы дачного домика до многосекционных локальных очистных систем коттеджных поселков.
                    <br /><br />
                    В чем отличие септика от выгребной ямы?
                </Text>

                <Box mt='40px' borderLeft='solid 3px orange' pl='10px' >
                    <Heading fontFamily='Twenty' fontSize='17px' w='80%' >
                        Мы оказываем услуги по строительству септиков и выгребных ям в {typeof props.city === "string" ? props.city : props.city.text2} "под ключ", а также выполняем их ремонт.
                    </Heading>
                    <Text fontFamily='Hero' mt='15px'>
                        В стоимость септика под ключ входит:<br />
                        - Доставка материала (кольца ж/б, щебень, цемент и т.д.);<br />
                        - Копка механизированным способом;<br />
                        - Врезка магистрали;<br />
                        - Монтаж колец и других элементов конструкции септика;<br />
                        - Отсыпка с уплотнением;<br />
                        - Разравнивание грунта (без утилизации излишков);<br />
                        - Гарантия.<br /><br />

                        Более подробно с услугой по строительству септика "под ключ" можете ознакомиться тут.
                    </Text>
                </Box>

                <Text fontFamily='Twenty' fontSize={30} mt='40px' >Выгребная яма</Text>
                <Text fontFamily='Hero' fontSize='17px' w='80%'  >
                    <br />
                    – это простейшая и, пожалуй, самая старая разновидность канализации, представляющая собой выемку в земле, собирающую отходы. Параметры сооружения (объем, площадь)  определяются величиной выливаемых сбросов, количеством жильцов дома и постоянностью проживания. При заполнении ямы вызывают ассенизаторов, которые специальной машиной выкачивают накопленное и вывозят. Для предотвращения попадания загрязнений в почву стены выгребной ямы бетонируют.<br /><br />

                    <span style={{ fontFamily: 'Twenty' }} >Плюсы выгребных ям:</span><br /><br />

                    – простота и экономичность сооружения;<br /><br />

                    – небольшая занимаемая площадь;<br /><br />

                    – возможность самостоятельного монтажа.<br /><br />

                    <span style={{ fontFamily: 'Twenty' }} >Минусы выгребных ям:</span><br /><br />

                    – наличие неприятного запаха;<br /><br />

                    – недолговечность;<br /><br />

                    – необходимость вызова специалистов для опустошения ямы;<br /><br />

                    – попадание непеработанных отходов в почву, загрязнение грунтовой воды<br /><br />

                    Что нужно знать при размещении выгребной ямы?<br /><br />

                    Есть действующие нормы, согласно которым регламентированы расстояния до всех объектов, которые могут находиться на вашем участке: до дома, до источника питьевой воды, до соседнего участка, до дороги… Кроме того, необходимость регулярного подъезда ассенизаторов диктует свои требования по расположению ямы. Глубину монтажа ямы планируют таким образом, чтобы уровень грунтовых вод поддерживался на уровне не выше 0,9 м от дна сооружения, но не ниже 3м от поверхности (иначе из-за своих особенностей ассенизаторская машина не справится с работой). Уровень промерзания грунта имеет немаловажное значение – основная масса отходов должна располагаться ниже.
                </Text>
                <Text fontFamily='Twenty' fontSize='30px' mt='40px' >Септик</Text>
                <Text fontFamily='Hero' fontSize='17px' w='80%' >
                    Септик – это более современный вид канализации частного дома, промежуточный вариант между выгребной ямой (где отходы просто накапливаются) и локальной станцией очистки (в функции которой входит не только накопление, но и переработка отходов). Отстаивание, применение специализированных аэробных и анаэробных бактерий, методы естественной и принудительной доочистки – все это позволяет перерабатывать стоки, получая на выходе очищенную до 80-90% воду. Необходимость в ассенизаторских услугах в случае использования септика снижается до одного раза в один-два года.<br /><br />

                    Такая система существенно дороже выгребной ямы и занимает гораздо больше места, к тому же не на всех участках можно установить септик – при высоких грунтовых водах емкости будут просто напросто выдавливаться из мест установки.<br /><br />

                    К плюсам септика можно отнести то, что он требует меньше обслуживания, не требует электричества, благодаря замкнуто-герметичной системе не распространяет неприятный запах и в целом более экологичнее.<br /><br />

                    Чем бы вы не решили заменить центральную систему канализации на своем участке, помните, что любая из вышеназванных конструкций должна устанавливаться согласно строительным правилам, чтобы не допустить отравление почвы, воды и, следовательно, людей.<br /><br />
                </Text>
            </Box>
        </Center>
    </Box>
}