import { Text, Box, Heading, Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export default function Article2(props) {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <Box w='100%' mt='150px'  >
        <Center>
            <Box w='85%' minW='1000px' maxW='1200px' >
                <Heading fontFamily='Twenty' fontSize="30px" >Колодцы для питьевой воды в {typeof props.city === "string" ? props.city : props.city.text2}</Heading>

                <Text fontFamily='Hero' mt='20px' fontSize='20px' w='80%' >
                    Колодец может быть дополнительным или основным источником питьевой воды. В колодцы так же как и в скважины можно установить насосное оборудование.
                    <br /><br />
                    Насос будет создавать необходимое давление, которое обеспечит работу всей системы водоснабжения. В таком случае это будет основная система водоснабжения, которой хватит для обеспечения жизнедеятельности дома для постоянного проживания.
                </Text>

                <Box mt='40px' borderLeft='solid 3px orange' pl='10px' >
                    <Heading fontFamily='Twenty' fontSize='17px' w='80%' >
                        Мы выполняем услуги по копке колодцев для питьевой воды "под ключ". В стоимость входит:
                    </Heading>
                    <Text fontFamily='Hero' mt='15px'>
                        - Поиск водоносного слоя рамкой;<br />
                        - Доставка материалов;<br />
                        - Копка колодца;<br />
                        - Монтаж колец;<br />
                        - Отсыпка донного фильтра;<br />
                        - Отсыпка шахты колодца по контуру с уплотнением;<br />
                        - Замазка швов.<br /><br />

                        Более подробно с услугой по строительству септика "под ключ" можете ознакомиться тут.
                    </Text>
                </Box>
                <Text fontFamily='Hero' fontSize='17px' w='80%' mt='40px' mb='20px' >
                    Дачные колодцы и колодцы для полива обычно имеют классическое устройство и предназначены больше для временного – сезонного использования.<br /><br/>

                    Колодцы для питьевой воды должны состоять из железобетонных колец, который выполнены особым образом. При их изготовление необходимо использовать специальную мытую арматуру, а обмазка опалубки не должна иметь нефтяных и жировых продуктов. В Остальном всё как обычно.<br /><br/>

                    Колодцы лучше всего выкапывать ручным способом до первого водоносного горизонта. “Первая” вода – это верховодка.
                </Text>
            </Box>
        </Center>
    </Box>
}