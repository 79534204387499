import {
    Text, Box, HStack, Icon, Center, Table, Thead, Tbody, Tr, Th, Td, TableContainer, SimpleGrid, GridItem, Image, Input, FormControl, FormLabel, Button, VStack
} from "@chakra-ui/react";
import CatalogSeptik1 from "../Catalogs/septik1";
import SeptikBiofor from "../Catalogs/septikBiofor";
import SeptikEcstra from "../Catalogs/septikEcstra";
import SeptikStandart from "../Catalogs/septikStandart";
import FormApplication from "../Form/index";
import { IoPricetags } from "react-icons/io5";
import { Fade } from "react-reveal";

export default function Septik(props) {
    return <>
        <Center>
            <Box w='100%' mt='40px' p='20px' mb='50px'>
                <Text float='left' textAlign='center' fontFamily='Twenty' fontSize='23pt'>Септики</Text>
                <Box w='100%' border='solid 0.5px gray' mt='60px' opacity='0.5' ></Box>
                <HStack mt='20px' >
                    <Icon as={IoPricetags} color='gold' w='35px' h='35px' />
                    <Text fontFamily='Hero' mt='15px' fontSize={22} >Септики для г. {typeof props.city === "string" ? props.city : props.city.text}</Text>
                </HStack>
                <Text fontSize='12pt' fontFamily='Twenty' mt='10px' >Септики из бетонных колец для дома и дачи. Продажа и установка под ключ в {typeof props.city === "string" ? props.city : props.city.text}</Text>
                <Text fontFamily='Hero' fontSize={14} mt='20px'>
                    Для дачи и домов сезонного проживания выполняют строительство септиков преимущественно из железобетонных колец с упрощенной конструкцией. Отличаются данные септики от септиков для домов с постоянным проживанием лишь немного более простой конструкцией и небольшим объёмом - чаще всего это две переливных камеры состоящих из двух жб колец диаметром не больше одного метра. Этого решения как правило хватает для подавляющего большинства дачных домов и других домов для временного (сезонного) проживания 3 человек.
                </Text>
                <Text fontFamily='Hero' fontSize={25} mt='20px' >Установка септика из жб колец по низкой цене в {typeof props.city === "string" ? props.city : props.city.text}</Text>
                <Center>
                    <TableContainer w='100%' >
                        <Table variant='striped' colorScheme='yellow' size='sm' >
                            <Thead>
                                <Tr>
                                    <Th>Тип</Th>
                                    <Th>Колец/шт</Th>
                                    <Th>Кол, чел</Th>
                                    <Th textAlign='center' >Описание</Th>
                                    <Th>Цена, руб.</Th>
                                    <Th>Сроки*</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Комплект 1</Td>
                                    <Td textAlign='center' >2</Td>
                                    <Td textAlign='center' >1</Td>
                                    <Td>Однокамерный слив условно чистой воды</Td>
                                    <Td textAlign='center' fontWeight='700' >11 000</Td>
                                    <Td textAlign='center' >За 1 день</Td>
                                </Tr>
                                <Tr>
                                    <Td>Комплект 2</Td>
                                    <Td textAlign='center' >3</Td>
                                    <Td textAlign='center' >1-2</Td>
                                    <Td>Однокамерный слив условно чистой воды</Td>
                                    <Td textAlign='center' fontWeight='700' >13 000</Td>
                                    <Td textAlign='center' >За 1 день</Td>
                                </Tr>
                                <Tr>
                                    <Td>Комплект 3</Td>
                                    <Td textAlign='center' >4</Td>
                                    <Td textAlign='center' >2-3</Td>
                                    <Td>Однокамерный слив условно чистой воды</Td>
                                    <Td textAlign='center' fontWeight='700' >16 000</Td>
                                    <Td textAlign='center' >За 1 день</Td>
                                </Tr>
                                <Tr>
                                    <Td>Комплект 4</Td>
                                    <Td textAlign='center' >4</Td>
                                    <Td textAlign='center' >2-3</Td>
                                    <Td>Двухкамерный слив условно чистой воды</Td>
                                    <Td textAlign='center' fontWeight='700' >20 000</Td>
                                    <Td textAlign='center' >За 2 дня</Td>
                                </Tr>
                                <Tr>
                                    <Td>Комплект 5</Td>
                                    <Td textAlign='center' >5</Td>
                                    <Td textAlign='center' >4</Td>
                                    <Td>Двухкамерный слив условно чистой воды</Td>
                                    <Td textAlign='center' fontWeight='700' >22 000</Td>
                                    <Td textAlign='center' >За 3 дня</Td>
                                </Tr>
                                <Tr>
                                    <Td>Комплект 6</Td>
                                    <Td textAlign='center' >6</Td>
                                    <Td textAlign='center' >1-2</Td>
                                    <Td>2-х камерные для постоянного проживания<br /> Для высоких грунтовых вод</Td>
                                    <Td textAlign='center' fontWeight='700' >23 500</Td>
                                    <Td textAlign='center' >За 1 день</Td>
                                </Tr>
                                <Tr>
                                    <Td> Макси</Td>
                                    <Td textAlign='center' >8</Td>
                                    <Td textAlign='center' >2-3</Td>
                                    <Td>2-х камерные для постоянного проживания<br /> Для высоких грунтовых вод</Td>
                                    <Td textAlign='center' fontWeight='700' >27 000</Td>
                                    <Td textAlign='center' >За 1 день</Td>
                                </Tr>
                                <Tr>
                                    <Td>К1</Td>
                                    <Td textAlign='center' >2</Td>
                                    <Td textAlign='center' >3-4</Td>
                                    <Td>Однокамерный для постоянного проживания.<br /> Для низких грунтовых вод</Td>
                                    <Td textAlign='center' fontWeight='700' >15 000</Td>
                                    <Td textAlign='center' >За 1 день</Td>
                                </Tr>
                                <Tr>
                                    <Td>К2</Td>
                                    <Td textAlign='center' >4</Td>
                                    <Td textAlign='center' >4-5</Td>
                                    <Td>2-х камерные для постоянного проживания.<br /> Для низких грунтовых вод</Td>
                                    <Td textAlign='center' fontWeight='700' >30 000</Td>
                                    <Td textAlign='center' >За 2 дня</Td>
                                </Tr>
                                <Tr>
                                    <Td>К-Макси</Td>
                                    <Td textAlign='center' >9</Td>
                                    <Td textAlign='center' >5-6</Td>
                                    <Td>Трёхкамерные для постоянного проживания.<br /> Для низких грунтовых вод</Td>
                                    <Td textAlign='center' fontWeight='700' >51 000</Td>
                                    <Td textAlign='center' >За 2 дня</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Center>
                <Box w='100%' bg='white' pt="50px" pb='70px' ><CatalogSeptik1 mt='0px' city={props.city} /></Box>
                <Center>
                    <Box w='95%' >
                        <Text fontFamily='Hero' fontSize={25} >Галерея выполненных работ</Text>
                        <Center>
                            <SimpleGrid columns={2} w='100%' gap='10px' mt='20px' >
                                <GridItem><Image src={require('../../../img/septik1.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik2.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik3.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik4.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik5.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik6.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik7.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/septik8.jpeg')} /></GridItem>
                            </SimpleGrid>
                        </Center>
                    </Box>
                </Center>
                <Fade left >
                    <Center >
                        <Box w='100%' mt='40px' border='solid 5px rgb(215,215,215)' bg='white' borderRadius={20} pb='15px' className="boxShadow" p='20px' >
                            <HStack>
                                <VStack w='315px' align='center' gap='-10px' >
                                    <FormControl w='100%' mt='10px' mb='30px' >
                                        <FormLabel fontFamily='Hero' ml='13px' fontSize={19} >Ваше Имя</FormLabel>
                                        <Input type='text' w='90%' ml='13px' border='solid 2px white' />
                                        <FormLabel fontFamily='Hero' ml='13px' fontSize={19}>Ваш номер</FormLabel>
                                        <Input type='number' w='90%' ml='13px' placeholder="+7 (986) 543-21-00" border='solid 2px white' />
                                    </FormControl>
                                    <VStack gap='5px'>
                                        <Button w='230px' colorScheme='yellow' color='white' >Оформить заявку</Button>
                                        <Button w='230px' colorScheme='orange' color='white' >Позвонить менеджеру</Button>
                                    </VStack>
                                </VStack>
                            </HStack>
                        </Box>
                    </Center >
                </Fade>

                <SeptikBiofor mt='20px' />
                <SeptikEcstra />
                <SeptikStandart />

             <Center>
                    <Box w='100%' mt='80px' >
                        <Text fontFamily='Hero' fontSize={25} >Фото установки септиков Биофор</Text>
                        <Center>
                            <SimpleGrid columns={2} w='100%' gap='10px' mt='20px' >
                                <GridItem><Image src={require('../../../img/biofor_1.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_2.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_3.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_4.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_5.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_6.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_7.jpeg')} /></GridItem>
                                <GridItem><Image src={require('../../../img/biofor_8.jpeg')} /></GridItem>
                            </SimpleGrid>
                        </Center>
                    </Box>
                </Center>

                   <Center>
                    <Text fontFamily='Hero' w='100%' fontSize={20} mt='30px' >
                        Выгребные ямы для дачи также имеют подобное отличие и выполняются из 3 колец диаметром в один метр.
                        <br /><br />
                        Предположим, что вы определились с типом сливной канализационной емкости, и, в момент поиска подрядчика, главным критерием для вас является цена.
                        <br /><br />
                        Помните простое правило, что "радость от низкой цены гораздо короче разочарования от низкого качества".
                        <br /><br />
                        Мы также не рвемся за золотыми слитками, но стараемся держать цену на среднем уровне. Цены от 4000 рублей за кольцо для колодца и 4500 рублей для септика мы считаем оправданным.
                        <br /><br />
                        Во-первых, мы используем заводские ж/б изделия (с документами). Для питьевых колодцев - только обезжиренные бетонные кольца диаметров 1 метр.
                        <br /><br />
                        Во-вторых, у нас весь свой инструмент и своя техника, мы ничего у вас не будем просить.
                        <br /><br />
                        В-третьих, мы заключаем договор, в котором обговариваем все условия и ни на дюйм больше!
                    </Text>
                </Center>

            </Box>
        </Center>
        <Box w='100%' h='500px' >
            <Center>
                <FormApplication city={props.city} />
            </Center>
        </Box>
    </>
}