import {
    FormControl,
    FormLabel,
    Input,
    Text,
    HStack,
    Button,
    Image,
    VStack
} from "@chakra-ui/react";
import { useState } from "react";
import axios from "axios";

export default function FormApplication(props) {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [text, setText] = useState("");

    return <VStack w='75%' mt='30px'>
        <Text p='10px' fontSize={25} fontFamily='Hero'>Остались вопросы? Оставьте заявку и мы свяжемся с Вами!</Text>
        <HStack spacing="30px">
            <VStack spacing="20px">
                <FormControl>
                    <FormLabel fontFamily='Hero' fontSize={19} onChange={setName}>Ваше Имя</FormLabel>
                    <Input type='text' border='solid 2px white' />
                    <FormLabel fontFamily='Hero' fontSize={19} onChange={setPhone}>Ваш номер</FormLabel>
                    <Input type='number' placeholder="+7 (986) 543-21-00" border='solid 2px white' />
                    <FormLabel fontFamily='Hero' fontSize={19} onChange={setText}>Текст</FormLabel>
                    <Input type='text' border='solid 2px white' />
                </FormControl>
                <HStack spacing="20px">
                    <Button w='50%' h='45px' colorScheme='yellow' color='white' onClick={async () => { await axios.post('https://api.twodev.cc/helper/septiki', {
                        city: props.city.text ? props.city.text : props.city,
                        info: `Главная страница; последняя форма; текст: ${text}`,
                        name: name,
                        phone: phone
                    })}}>Оформить заявку</Button>
                    <Button w='50%' h='45px' colorScheme='orange' color='white'>Позвонить менеджеру</Button>
                </HStack>
            </VStack>
            <Image w='450px' h='400px' src={require('../../../img/applicationImg.png')} />
        </HStack>
    </VStack>
}