import { Text, Box, Heading, Center, Image } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export default function Article4(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return <Box w='100%' mt='150px'  >
        <Center>
            <Box w='85%' minW='1000px' maxW='1200px' >
                <Heading fontFamily='Twenty' fontSize="30px" >Ремонт питьевых колодцев в {typeof props.city === "string" ? props.city : props.city.text2}</Heading>

                <Text fontFamily='Hero' mt='20px' fontSize='20px' w='80%' >
                    Ремонт колодцев осуществляется только ручным способом с погружением в ствол колодца.Частые неисправности колодцев: сдвиг колец, разрушение колец, протечка верховодки и т. д.<br/><br />

                    Ремонт колодцев осуществляется исключительно ручным способом с погружением в ствол.<br/><br />

                    Если сдвиг колец произошел на глубине промерзания, то колодец обкапывают и устраняют нарушение соосности колец. после устранения устанавливают металлические скобы и бетонируют. Если же сдвиг произошел на значительной глубине, то кольцо демонтируют, а её место бетонируют.<br/><br />

                    Разрушение колец устраняют специальными растворами, в которых содержится жидкое стекло или другие добавки. Обычно поврежденный участок демонтируется и заливается бетоном.<br/><br />

                    Протечки колодцев чаще всего происходит по стыку кольца или через монтажные отверстия. эти места тщательно герметизируются.<br/><br />

                    Ремонт колодцев от 2500 рублей.<br/><br />
                </Text>
                <Center><Image src={require('../../../../img/remontWell.jpeg')} mb='40px' /></Center>
            </Box>
        </Center>
    </Box>
}