import {
    Box,
    Center,
    Heading,
    Image,
    HStack,
    Text,
    SimpleGrid,
    GridItem,
    UnorderedList,
    ListItem
} from "@chakra-ui/react";
import AppModal from "../AppModal";

export default function SeptikBiofor(props) {

    return <Center>
        <Box w='90%' mt='200px'>
            <Heading textAlign='center' fontSize='27pt' fontWeight={500} mt={props.mt} >СЕПТИКИ БИОФОР</Heading>
            <HStack mt='65px' >
                <Image src={require('../../../img/biofor1.jpeg')} ml='3%'/>
                <Text w='50%' fontSize={18} >
                    Септики Биофор представляет собой цельнолитую пластиковую ёмкость, внутри которой разделён на две или три камеры, в зависимости от выбраной модели.
                    <br /><br />
                    Модель септика Профи - абсолютно энергозависима, в процессе очистки сточных вод участвуют бактерии анаэробного типа. которые менее прихотливы, нежели бактерии, зависящие от постоянной подачи кислорода.
                    <br /><br />
                    Модель Экстра - работает по такому же принципу, но выброс сточных вод из него осуществляется с помощью дренажного насоса, что делает даннкю модель энергозависимой.В фильтрующем отсеке возможно 2 варианта очистки:Техническая пемза или пластиковые шайбы.
                </Text>
            </HStack>

            <Center>
                <Text w='90%' textAlign='left' fontFamily='Twenty' fontSize={20} mt='80px' >Серия Профи</Text>
            </Center>
            <Center >
                <Text w='90%' textAlign='left' fontFamily='Hero' fontSize={18} >
                    Энергонезависимые септики серии Профи не требуют постоянной откачки и подойдут для большинства участков.
                </Text>
            </Center>

            <Center>
                <Box mt='40px' w='90%' >
                    <SimpleGrid columns={4} gap='20px' >
                        <GridItem ml='0px' >
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/biofor2.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px' >
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 0,9 Профи</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 1600 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 1430х930х1600</ListItem>
                                        <ListItem>Общий объём: 900 л</ListItem>
                                        <ListItem>Вес 60 кг</ListItem>
                                        <ListItem>Кол-во пользователей 1-2 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >18 400 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 0,9 Профи" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/biofor4.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px'>
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 2.0 Профи</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 2000 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 2000х1000х2000</ListItem>
                                        <ListItem>Общий объём: 2000 л</ListItem>
                                        <ListItem>Вес 125 кг</ListItem>
                                        <ListItem>Кол-во пользователей 3-4 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >34 400 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 2.0 Профи" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/biofor5.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px'>
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 2.5 Профи</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 2100 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 1750х1450х2100</ListItem>
                                        <ListItem>Общий объём: 2500 л</ListItem>
                                        <ListItem>Вес 145 кг</ListItem>
                                        <ListItem>Кол-во пользователей 4-5 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >38 700 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 2.5 Профи" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box w='290px' h='752px' border='solid 1px rgb(245,245,245)' textAlign='center' >
                                <Box h='280px' w='100%'>
                                    <Image src={require('../../../img/biofor3.jpeg')} />
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt='18px'>
                                    <Text textAlign='center' fontSize='15pt' fontWeight={400} fontFamily='Hero' >Септик Биофор 3.0 Профи</Text>
                                </Box>
                                <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                    <Center>
                                        <hr style={{
                                            color: '#000000',
                                            backgroundColor: '#000000',
                                            height: 0.6,
                                            borderColor: '#000000',
                                            width: 70,
                                            opacity: 0.4
                                        }} />
                                    </Center>
                                </Box>
                                <Box h='160px' bg='rgb(235,235,235)' p={3} >
                                    <UnorderedList textAlign='left' fontSize={14} p={1.3} fontFamily='Hero' >
                                        <ListItem>Работает с системой фильтрации</ListItem>
                                        <ListItem>Высота емкости 2100 мм</ListItem>
                                        <ListItem>Габариты (ДхШхВ): 2130х1360х2100</ListItem>
                                        <ListItem>Общий объём: 3000 л</ListItem>
                                        <ListItem>Вес 160 кг</ListItem>
                                        <ListItem>Кол-во пользователей 5-6 человека</ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                    <Text fontSize={45} fontFamily="Twenty" >45 300 ₽</Text>
                                </Box>
                                <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                    <AppModal city={props.city} septik="Септик Биофор 3.0 Профи" />
                                </Box>
                                <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                    <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                                </Box>
                            </Box>
                        </GridItem>
                    </SimpleGrid>

                </Box>
            </Center>
        </Box >
    </Center >
}