import {
    Box,
    Button,
    Flex,
    Heading, HStack,
    Image,
    Input,
    InputGroup,
    InputLeftAddon, ListItem,
    Select,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack, Table, TableContainer, Tbody, Td,
    Text, Th, Thead, Tr, UnorderedList,
    useToast,
    VStack
} from "@chakra-ui/react";
import React from "react";
import axios from "axios";

export default function DiggingWells(props) {
    const [sliderValue, setSliderValue] = React.useState(10);
    const [type, setType] = React.useState("1");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const toast = useToast();

    const handleSend = async which => {
        console.log(type, sliderValue, name, phone);

        if (name.length < 2 || phone.length < 10) toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        }); else {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            await axios.post('https://api.twodev.cc/helper/septiki', {
                city: props.city.text ? props.city.text : props.city,
                info: `Копка колодцев; ${which === 1 ? `услуга: ${type}, глубина колодца: ${sliderValue}м` : `услуга: ${which}`}`,
                name: name,
                phone: phone
            });
        };
    };

    return <>
        <Image src={require('../../../img/bg2.jpg')} style={{ paddingTop: "10%", position: "fixed", zIndex: -1000, bgSize: "cover", scale: "4", filter: "brightness(30%)" }} />
        <VStack w="100%" py="10vh" px="10vw" spacing="60px">
            <VStack w="100%" spacing="14px">
                <Box align="center">
                    <Heading color="white">Копка колодцев под ключ в {props.city.text2 ? props.city.text2 : props.city}</Heading>
                    <Heading color="white">Ремонт • Чистка • Углубление</Heading>
                </Box>
                <Text color="white" fontSize="18pt" align="center">От 2 000 руб за кольцо</Text>
                <Text color="white" fontSize="18pt" align="center">Ремонт любой сложности</Text>
                <Text color="white" fontSize="18pt" align="center">Демонтаж и монтаж домика бесплатно</Text>
            </VStack>
            <Box border="6px solid white" bgColor="#363636" p="18px" align="center">
                <Heading fontSize="16px" color="white">РАСЧЁТ СТОИМОСТИ</Heading>
                <Text fontSize="14px" color="#999999" my="14px">Введите параметры и получите расчёт за 3 минуты</Text>
                <Select color='gray' onChange={event => setType(event.target.value)}>
                    <option value='1'>Копка колодца под ключ</option>
                    <option value='2'>Ремонт колодца</option>
                    <option value='3'>Углубление колодца</option>
                    <option value='4'>Чистка колодца</option>
                </Select>
                <Box w="100%" justify="center" my="20px">
                    <Text color="gray">Глубина колодца</Text>
                    <Slider aria-label='slider-ex-1' defaultValue={10} min={3} max={20} step={1} onChange={(val) => setSliderValue(val)} colorScheme='orange'>
                        <SliderMark
                            value={sliderValue}
                            textAlign='center'
                            bg='orange.500'
                            color='white'
                            mt='10px'
                            ml='-5'
                            w='40px'
                            borderRadius="10px"
                        >
                            {sliderValue} м
                        </SliderMark>
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                </Box>
                <Input my="16px" onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                <InputGroup>
                    <InputLeftAddon children='+7' />
                    <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' color='white' />
                </InputGroup>
                <Button colorScheme='orange' my="16px" onClick={async () => { await handleSend(1); }}>Рассчитать</Button>
                <Text color="#b3b1b1" fontSize="10px">Отправляя данные, Вы даёте согласие на обработку персональных данных</Text>
            </Box>
        </VStack>

        <Box w='100%' bg='white' px="6vw" py="10vh">
            <VStack spacing="10vh">
                <VStack spacing="4vw">
                    <Image src={require("../../../img/kolodec.png")} />
                    <Box>
                        <Heading fontSize="24px">Копка колодца под ключ</Heading>
                        <Text fontSize="20px" my="6px">Что входит стоимость копки колодца:</Text>
                        <UnorderedList>
                            <ListItem>Пазогребневые бетонные кольца с внутренним диаметром 1 метр</ListItem>
                            <ListItem>Копка ствола колодца и монтаж колец</ListItem>
                            <ListItem>Замазка швов жидким стеклом</ListItem>
                            <ListItem>Доставка колец и инертного материала: песка или гравия</ListItem>
                            <ListItem>Монтаж оголовка и системы подъёма ведра</ListItem>
                            <ListItem>Гарантия на колодец</ListItem>
                            <ListItem>* оголовок колодца приобретается отдельно</ListItem>
                        </UnorderedList>
                        <VStack border="2px solid gray" p="20px">
                            <VStack>
                                <Image src={require("../../../img/samoletik.png")} />
                                <Text fontSize="18px" textAlign="center">Заказ копки колодца</Text>
                                <Text fontSize="12px" textAlign="center">Введите данные и наш менеджер свяжется с Вами</Text>
                            </VStack>
                            <VStack spacing="14px">
                                <Input onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                                <InputGroup>
                                    <InputLeftAddon children='+7' />
                                    <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' color='white' />
                                </InputGroup>
                                <Button colorScheme='orange' w="100%" onClick={async () => { await handleSend("Заказ копки колодца"); }}>ЗАКАЗАТЬ</Button>
                            </VStack>
                        </VStack>
                    </Box>
                </VStack>
                <VStack spacing="4vw">
                    <Image src={require("../../../img/kolodec.png")} />
                    <Box>
                        <Heading fontSize="24px">Ремонт колодца</Heading>
                        <Text fontSize="20px" my="6px">Что входит в стоимость ремонта колодца:</Text>
                        <UnorderedList>
                            <ListItem>Выезд специалиста</ListItem>
                            <ListItem>Исследование степени повреждения</ListItem>
                            <ListItem>Устранение повреждения</ListItem>
                            <ListItem>Гарантия на ремонт колодца</ListItem>
                            <ListItem>* Стоимость ремонта от 2500 руб</ListItem>
                        </UnorderedList>
                        <VStack border="2px solid gray" p="20px">
                            <VStack>
                                <Image src={require("../../../img/remont_kolodtsa_5.png")} />
                                <Text fontSize="18px" textAlign="center">Заказ ремонта колодца</Text>
                                <Text fontSize="12px" textAlign="center">Введите данные и наш менеджер свяжется с Вами</Text>
                            </VStack>
                            <VStack spacing="14px">
                                <Input onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                                <InputGroup>
                                    <InputLeftAddon children='+7' />
                                    <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' color='white' />
                                </InputGroup>
                                <Button colorScheme='orange' w="100%" onClick={async () => { await handleSend("Заказ ремонта колодца"); }}>ЗАКАЗАТЬ</Button>
                            </VStack>
                        </VStack>
                    </Box>
                </VStack>
                <VStack spacing="4vw">
                    <Image src={require("../../../img/kolodec.png")} />
                    <Box>
                        <Heading fontSize="24px">Углубление колодца</Heading>
                        <Text fontSize="20px" my="6px">Что входит в стоимость углубления колодца:</Text>
                        <UnorderedList>
                            <ListItem>Демонтажные работы</ListItem>
                            <ListItem>Демонтаж оголовка производится бесплатно</ListItem>
                            <ListItem>* Стоимость углубления колодца от 10 000 руб до 20 колец</ListItem>
                        </UnorderedList>
                        <VStack border="2px solid gray" p="20px">
                            <VStack>
                                <Image src={require("../../../img/glyblenie.jpg")} />
                                <Text fontSize="18px" textAlign="center">Заказ углубления колодца</Text>
                                <Text fontSize="12px" textAlign="center">Введите данные и наш менеджер свяжется с Вами</Text>
                            </VStack>
                            <VStack spacing="14px">
                                <Input onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                                <InputGroup>
                                    <InputLeftAddon children='+7' />
                                    <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' color='white' />
                                </InputGroup>
                                <Button colorScheme='orange' w="100%" onClick={async () => { await handleSend("Заказ углубления колодца"); }}>ЗАКАЗАТЬ</Button>
                            </VStack>
                        </VStack>
                    </Box>
                </VStack>
                <VStack spacing="4vw">
                    <Image src={require("../../../img/kolodec.png")} />
                    <Box>
                        <Heading fontSize="24px">Чистка колодца</Heading>
                        <Text fontSize="20px" my="6px">Что входит в стоимость чистки колодца:</Text>
                        <UnorderedList>
                            <ListItem>Откачка воды</ListItem>
                            <ListItem>Чистка дна и стен от мха и ила</ListItem>
                            <ListItem>Заделка швов гидрозамком</ListItem>
                            <ListItem>Дезинфекция</ListItem>
                            <ListItem>* Стоимость чистки колодца от 12 000 руб</ListItem>
                        </UnorderedList>
                        <VStack border="2px solid gray" p="20px">
                            <VStack>
                                <Image src={require("../../../img/chistka_2.jpg")} />
                                <Text fontSize="18px" textAlign="center">Заказ чистки колодца</Text>
                                <Text fontSize="12px" textAlign="center">Введите данные и наш менеджер свяжется с Вами</Text>
                            </VStack>
                            <VStack spacing="14px">
                                <Input onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                                <InputGroup>
                                    <InputLeftAddon children='+7' />
                                    <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' color='white' />
                                </InputGroup>
                                <Button colorScheme='orange' w="100%" onClick={async () => { await handleSend("Заказ чистки колодца"); }}>ЗАКАЗАТЬ</Button>
                            </VStack>
                        </VStack>
                    </Box>
                </VStack>
                <VStack spacing="10vw">
                    <Box>
                        <Heading fontSize="24px">На стоимость ремонта может влиять:</Heading>
                        <br />
                        <UnorderedList>
                            <ListItem>Степень повреждения</ListItem>
                            <ListItem>Состояние швов и обмазки</ListItem>
                            <ListItem>Степень загрязнения стен и дна</ListItem>
                        </UnorderedList>
                    </Box>
                    <Box>
                        <Heading fontSize="24px">Стоимость дополнительных работ:</Heading>
                        <br />
                        <UnorderedList>
                            <ListItem>Дополнительное скобирование - 300 руб</ListItem>
                            <ListItem>Утилизация загрязнений (ил, мох и другие предметы) - 1 500 руб</ListItem>
                            <ListItem>Замена верхнего кольца - 3000 руб</ListItem>
                        </UnorderedList>
                    </Box>
                </VStack>

                <VStack w="100%" spacing={5}>
                    <Heading fontFamily='Twenty' fontSize={20} >Цены на услуги копки колодца в {props.city.text2 ? props.city.text2 : props.city}</Heading>
                    <TableContainer w="100%">
                        <Table variant='striped' colorScheme='yellow' textAlign='center' size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Наименование работ</Th>
                                    <Th isNumeric>Стоимость работ, руб.</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Стоимость копки колодца</Td>
                                    <Td></Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца от 1-по 10</Td>
                                    <Td isNumeric fontWeight={800}>2000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца в сложных грунтах глина с камнями</Td>
                                    <Td isNumeric fontWeight={800}>4000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Чистка колодца одно кольцо</Td>
                                    <Td isNumeric fontWeight={800}>2500</Td>
                                </Tr>
                                <Tr>
                                    <Td>Кольца колодцев</Td>
                                    <Td isNumeric fontWeight={800}>от 1600</Td>
                                </Tr>
                                <Tr>
                                    <Td>Дренажное дно (щебень)</Td>
                                    <Td isNumeric fontWeight={800}>1000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Стоимость устройства септика из бетонных колец Д. 1,5 метр</Td>
                                    <Td isNumeric fontWeight={800}></Td>
                                </Tr>
                                <Tr>
                                    <Td>Бетонное кольцо с дном (стакан) кольцо+копка</Td>
                                    <Td isNumeric fontWeight={800}>10000</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>

                <VStack border="2px solid gray" p="20px">
                    <VStack>
                        <Image src={require("../../../img/price.jpg")} />
                        <Text fontSize="18px" textAlign="center">ПОЛУЧИТЕ ПРАЙС-ЛИСТ С АКТУАЛЬНЫМИ ЦЕНАМИ</Text>
                        <Text fontSize="12px" textAlign="center">Введите данные и мы сформируем актуальный прайс-лист</Text>
                    </VStack>
                    <VStack spacing="14px">
                        <Input onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                        <InputGroup>
                            <InputLeftAddon children='+7' />
                            <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон' color='white' />
                        </InputGroup>
                        <Button colorScheme='orange' w="100%" onClick={async () => { await handleSend("Прайс-лист"); }}>ЗАКАЗАТЬ</Button>
                    </VStack>
                </VStack>

                <VStack spacing="3vh">
                    <Heading align="center">Фото работ копки колодцев под ключ</Heading>
                    <Image src={require("../../../img/photos1.jpg")} />
                </VStack>
            </VStack>
        </Box>
    </>
}