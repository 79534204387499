import {
    Box,
    Text,
    HStack,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Input,
    List,
    ListItem
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React from "react";
import { useDisclosure, Link } from "@chakra-ui/react";
import cities from "../../../cities.json";

const finalAll = [];
Object.values(cities).filter(c => c.cities).map(c => Object.entries(c.cities).map(x => (finalAll[x[0]] = x[1])));

export default function CityPicker(props) {
    const [selectedOblast, setSelectedOblast] = React.useState("moskovskaya-oblast");
    const [cityFilter, setCityFilter] = React.useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    return <Box>
        <Button ref={btnRef}
                color='black'
                onClick={onOpen}
                rightIcon={<ChevronDownIcon />}>
            {props.city.text ? props.city.text : props.city}
        </Button>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
            size='lg'
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>Выберите Ваш город</DrawerHeader>

                <DrawerBody>
                    <Input placeholder='Найти город...' onChange={event => setCityFilter(event.target.value)} />
                    <HStack w='100%' mt='20px' align="start">
                        <Box w='50%'>
                            <Text fontFamily='Twenty' fontSize={30} color='gray'>Регионы</Text>
                            <List>
                                {cityFilter
                                    ? Object.entries(cities).filter(c => c[1].text.toLowerCase().includes(cityFilter.toLowerCase())).map(c => <ListItem
                                        style={{ borderLeft: selectedOblast === c[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                        _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}
                                        onClick={() => setSelectedOblast(c[0])}>
                                        {c[1].text}
                                    </ListItem>)
                                    : Object.entries(cities).map(c =>
                                        <ListItem
                                            style={{ borderLeft: selectedOblast === c[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                            _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}
                                            onClick={() => setSelectedOblast(c[0])}>
                                            {c[1].text}
                                        </ListItem>
                                    )}
                            </List>
                        </Box>
                        <Box borderLeftWidth='1px' pl='20px'>
                            <Text fontFamily='Twenty' fontSize={30} color='gray'>Города</Text>
                            <List>
                                {cityFilter
                                    ? Object.entries(finalAll).filter(c => (typeof c[1] === "string" ? c[1] : c[1].text).toLowerCase().includes(cityFilter.toLowerCase())).map(x =>
                                        <Link href={`/${x[0]}`} style={{ textDecoration: "none" }}>
                                            <ListItem
                                                style={{ borderLeft: selectedOblast === x[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}>
                                                {typeof x[1] === "string" ? x[1] : x[1].text}
                                            </ListItem>
                                        </Link>
                                    )
                                    : Object.entries(cities[selectedOblast].cities).map(x =>
                                        <Link href={`/${x[0]}`} style={{ textDecoration: "none" }}>
                                            <ListItem
                                                style={{ borderLeft: selectedOblast === x[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}>
                                                {typeof x[1] === "string" ? x[1] : x[1].text}
                                            </ListItem>
                                        </Link>
                                    )}
                            </List>
                        </Box>
                    </HStack>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Закрыть
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </Box>
}