import {
    Box,
    Center,
    HStack,
    Icon,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer
} from "@chakra-ui/react";
import { IoPricetags } from "react-icons/io5";
import { useLocation } from "react-router";
import { useEffect } from "react";

export default function Price(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <Box>
        <Center>
            <Box w='95%' mt='40px' p='10px' mb='50px'>
                <Text float='left' textAlign='center' fontFamily='Twenty' fontSize='23pt'>Цена</Text>
                <Box w='100%' border='solid 0.5px gray' mt='60px' opacity='0.5'></Box>
                <HStack mt='20px'>
                    <Icon as={IoPricetags} color='gold' w='35px' h='35px' />
                    <Text fontFamily='Hero' mt='15px' fontSize={22}>Прайс для г. {typeof props.city === "string" ? props.city : props.city.text}</Text>
                </HStack>
                <Text fontSize='16pt' fontFamily='Twenty' mt='10px'>Актуальная стоимость услуг для г. {typeof props.city === "string" ? props.city : props.city.text}</Text>
                <Center>
                    <TableContainer w='100%' mt='30px'>
                        <Table variant='striped' colorScheme='yellow' textAlign='center' size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Наименование работ</Th>
                                    <Th isNumeric>Стоимость работ, руб.</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Стоимость копки колодца</Td>
                                    <Td></Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца от 1-по 10</Td>
                              
                                    <Td isNumeric fontWeight={800}>2000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца от 11-по 15</Td>
                              
                                    <Td isNumeric fontWeight={800}>2500</Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца от 16-по 20</Td>
                              
                                    <Td isNumeric fontWeight={800}>3000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца от 21-по 25</Td>
                              
                                    <Td isNumeric fontWeight={800}>3500</Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца от 26-по 30</Td>
                              
                                    <Td isNumeric fontWeight={800}>4000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Копка одного метрового кольца в сложных грунтах глина с камнями</Td>
                              
                                    <Td isNumeric fontWeight={800}>4000</Td>
                                </Tr>
                                <Tr>
                                    <Td>-песчаный плывун</Td>
                              
                                    <Td isNumeric fontWeight={800}>4000</Td>
                                </Tr>
                                <Tr>
                                    <Td>-глинистый плывун</Td>
                              
                                    <Td isNumeric fontWeight={800}>4000</Td>
                                </Tr>
                                <Tr>
                                    <Td>-известняк 1-ой категории</Td>
                              
                                    <Td isNumeric fontWeight={800}>12000</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Center>
            </Box>
        </Center>
    </Box>
}