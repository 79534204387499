import {
    Box,
    Text,
    Image,
    Icon,
    HStack,
    VStack,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Input,
    List,
    ListItem
} from "@chakra-ui/react";
import { TimeIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import React from "react";
import { useDisclosure, Link } from "@chakra-ui/react";
import MenuBar from './menu';
import cities from "../../../cities.json";
import Phone from "../../Phone";

const finalAll = [];
Object.values(cities).filter(c => c.cities).map(c => Object.entries(c.cities).map(x => (finalAll[x[0]] = x[1])));

export default function Navbar(props) {
    const [selectedOblast, setSelectedOblast] = React.useState("moskovskaya-oblast");
    const [cityFilter, setCityFilter] = React.useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    return <Box w="100%" mb="13vh">
        <Box w='100%' position="fixed" zIndex={5} top={0}>
            <VStack bg="#323232">
                <HStack gap='20px' justify="center" align="center" my="14px">
                    <Image src={require('../../../img/logo.png')} />

                    <HStack spacing="30px" justify="center" align="center">
                        <HStack>
                            <Icon as={FaMapMarkerAlt} style={{ color: 'gold', width: '25px', height: '25px' }} />
                            <Box>
                                <Text color="white" fontWeight={600} fontSize={18}>Адрес</Text>
                                <Text mt='0px' color='white' fontSize={17}>Проспект Королёва, 10</Text>
                            </Box>
                        </HStack>
                        <HStack>
                            <a href="https://wa.me/">
                                <Image
                                    src={require('../../../img/whatsapp.svg').default}
                                    w='30px'
                                    h='30px'
                                />
                            </a>
                            <a href="tel:" >
                                <Icon as={FaPhoneAlt} style={{ color: 'gold', width: '25px', height: '25px' }} />
                            </a>
                            <Box>
                                <Text color="white" fontWeight={600} fontSize={18}>Телефон</Text>
                                <Phone city={props.city} />                                
                            </Box>
                        </HStack>
                        <Box>
                            <TimeIcon color='gold' w='30px' h='30px' float='left' mt={3} />
                            <Box>
                                <Text color="white" fontWeight={600} fontSize={18} ml={10} >Время работы</Text>
                                <Text mt='0px' color='white' fontSize={17} ml={10} >ПН-ВС с 9:00 до 20:00</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                ref={btnRef}
                                bg='white'
                                onClick={onOpen}                                
                                rightIcon={<ChevronDownIcon />} >
                                {props.city.text ? props.city.text : props.city}
                            </Button>
                            <Drawer
                                isOpen={isOpen}
                                placement='right'
                                onClose={onClose}
                                finalFocusRef={btnRef}
                                size='lg'
                            >
                                <DrawerOverlay />
                                <DrawerContent>
                                    <DrawerCloseButton />
                                    <DrawerHeader borderBottomWidth='1px'>Выберите Ваш город</DrawerHeader>

                                    <DrawerBody>
                                        <Input placeholder='Найти город...' onChange={event => setCityFilter(event.target.value)} />
                                        <HStack w='100%' mt='20px' align="start">
                                            <Box w='50%' >
                                                <Text fontFamily='Twenty' fontSize={40} color='gray' >Регионы</Text>
                                                <List>
                                                    {cityFilter
                                                        ? Object.entries(cities).filter(c => c[1].text.toLowerCase().includes(cityFilter.toLowerCase())).map(c => <ListItem
                                                            style={{ borderLeft: selectedOblast === c[0] ? "4px solid orange": "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                            _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}
                                                            onClick={() => setSelectedOblast(c[0])}>
                                                            {c[1].text}
                                                        </ListItem>)
                                                        : Object.entries(cities).map(c =>
                                                        <ListItem
                                                            style={{ borderLeft: selectedOblast === c[0] ? "4px solid orange": "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                            _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}
                                                            onClick={() => setSelectedOblast(c[0])}>
                                                                {c[1].text}
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Box>
                                            <Box borderLeftWidth='1px' pl='20px'> 
                                                <Text fontFamily='Twenty' fontSize={40} color='gray' >Города</Text>
                                                <List>
                                                    {cityFilter
                                                        ? Object.entries(finalAll).filter(c => (typeof c[1] === "string" ? c[1] : c[1].text).toLowerCase().includes(cityFilter.toLowerCase())).map(x =>
                                                            <Link href={`/${x[0]}`} style={{ textDecoration: "none" }}>
                                                                <ListItem
                                                                    style={{ borderLeft: selectedOblast === x[0] ? "4px solid orange": "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                                    _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}>
                                                                        {typeof x[1] === "string" ? x[1] : x[1].text}
                                                                </ListItem>
                                                            </Link>
                                                        )
                                                        : Object.entries(cities[selectedOblast].cities).map(x =>
                                                            <Link href={`/${x[0]}`} style={{ textDecoration: "none" }}>
                                                                <ListItem
                                                                    style={{ borderLeft: selectedOblast === x[0] ? "4px solid orange": "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                                    _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}>
                                                                        {typeof x[1] === "string" ? x[1] : x[1].text}
                                                                </ListItem>
                                                            </Link>
                                                        )}
                                                </List>
                                            </Box>
                                        </HStack>
                                    </DrawerBody>

                                    <DrawerFooter>
                                        <Button variant='outline' mr={3} onClick={onClose}>
                                            Закрыть
                                        </Button>
                                    </DrawerFooter>
                                </DrawerContent>
                            </Drawer>
                        </Box>
                    </HStack>
                </HStack>
                <MenuBar />
            </VStack>
        </Box>
    </Box >
}