import Navbar from "./components/Mobile/Navbar/index";
import React from "react";
import Footer from "./components/Mobile/Footer";
import Main from "./components/Mobile/Page/main";
import Price from './components/Mobile/Page/price';
import DiggingWells from './components/Mobile/Page/diggingWells';
import Septik from './components/Mobile/Page/septik';
import Services from './components/Mobile/Page/servicesPage';
import Contact from './components/Mobile/Page/contact';
import Article1 from "./components/Mobile/Infos/child/article1";
import Article2 from "./components/Mobile/Infos/child/article2";
import Article3 from "./components/Mobile/Infos/child/article3";
import Article4 from "./components/Mobile/Infos/child/article4";
import Article5 from "./components/Mobile/Infos/child/article5";
import Article6 from "./components/Mobile/Infos/child/article6";

export default function MobileApp(props) {
    return <>
        <Navbar city={props.city} />

        {
            props.page === "dw"
                ? <DiggingWells city={props.city} />
                : props.page === "contact"
                ? <Contact city={props.city} />
                : props.page === "price"
                    ? <Price city={props.city} />
                    : props.page === "septik"
                        ? <Septik city={props.city} />
                        : props.page === "services"
                            ? <Services city={props.city} />
                            : props.page === "a1"
                                ? <Article1 city={props.city} />
                                : props.page === "a2"
                                    ? <Article2 city={props.city} />
                                    : props.page === "a3"
                                        ? <Article3 city={props.city} />
                                        : props.page === "a4"
                                            ? <Article4 city={props.city} />
                                            : props.page === "a5"
                                                ? <Article5 city={props.city} />
                                                : props.page === "a6"
                                                    ? <Article6 city={props.city} />
                                                    : <Main city={props.city} />
        }

        <Footer />
    </>
}