import {
    Box,
    Image,
    HStack
} from "@chakra-ui/react";
import React from "react";
import MenuBar from './menu';

export default function Navbar(props) {
    return <Box w="100%" mb="12vh">
        <Box w='100%' position="fixed" zIndex={5} top={0}>
            <HStack bg="#323232" px="10vw">
                <MenuBar city={props.city} />
                <Image src={require('../../../img/logo.png')} w="250px" p="20px" />
            </HStack>
        </Box>
    </Box>
}