import {
    Text, VStack, Heading, HStack, Box, Icon, Button, Center, Image, SimpleGrid
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import { IoPricetags } from "react-icons/io5";

export default function ServicesPage(props) {
    function getLink(target) {
        const first = window.location.pathname.slice(1).split("/")[0];
        return ["", "dw", "price", "septik", "services", "contact"].includes(first) ? `${target}` : `/${first}${target}`;
    }

    return <>
        <Center>
            <Box w='90%' mt='40px' p='20px' mb='0px'>
                <Text float='left' textAlign='left' fontFamily='Twenty' fontSize='23pt'>Наши услуги</Text>
                <Box w='100%' border='solid 0.5px gray' mt='60px' opacity='0.5'></Box>
                <HStack mt='20px'>
                    <Icon as={IoPricetags} color='gold' w='35px' h='35px' />
                    <Text fontFamily='Hero' mt='15px' fontSize={22}>Список наших услуг {typeof props.city === "string" ? props.city : props.city.text}</Text>
                </HStack>
                <Text fontSize='16pt' fontFamily='Hero' mt='10px'>Колодцы и выгребные ямы на любых грунтах на вашей даче или дома по договору с гарантией</Text>
            </Box>
        </Center>
        <VStack mt="3vh" mb="12vh" spacing="6vh">
            <VStack>
                <VStack bgColor="#FFE44A" p="20px" spacing="18px">
                    <Text>Колодец - питьевая вода</Text>
                    <Box>
                        <Heading>от 4000</Heading>
                        <Text textAlign="center">РУБ/кольцо</Text>
                    </Box>
                    <Box>
                        <Text textAlign="center">Что входит:</Text>
                        <Text>- поиск водоносного слоя;</Text>
                        <Text>- ручная копка;</Text>
                        <Text>- обезжиренные ж/б кольца;</Text>
                        <Text>- монтаж;</Text>
                        <Text>- отсыпка шахты;</Text>
                        <Text>- доставка;</Text>
                        <Text>- отсыпка фильтра.</Text>
                    </Box>
                    <Text>Скидки на заказ нескольких колодцев</Text>
                    <Text>(Консультации бесплатны)</Text>
                    <Link to={getLink("/contact")}><Button bgColor="#333333" p="14px"><Text color="#FFE44A">СВЯЗАТЬСЯ</Text></Button></Link>
                </VStack>
                <VStack bgColor="#FFE44A" p="20px" spacing="18px">
                    <Text>Колодец - поливная вода</Text>
                    <Box>
                        <Heading>от 3500</Heading>
                        <Text textAlign="center">РУБ/кольцо</Text>
                    </Box>
                    <Box>
                        <Text textAlign="center">Что входит:</Text>
                        <Text>- поиск водоносного слоя;</Text>
                        <Text>- ручная копка;</Text>
                        <Text>- ж/б кольца;</Text>
                        <Text>- монтаж;</Text>
                        <Text>- отсыпка шахты;</Text>
                        <Text>- доставка;</Text>
                        <Text>- отсыпка донного фильтра.</Text>
                    </Box>
                    <Text>Скидки на заказ нескольких колодцев</Text>
                    <Text>(Консультации бесплатны)</Text>
                    <Link to={getLink("/contact")}><Button bgColor="#333333" p="14px"><Text color="#FFE44A">СВЯЗАТЬСЯ</Text></Button></Link>
                </VStack>
                <VStack bgColor="#FFE44A" p="20px" spacing="18px">
                    <Text>Колодец - питьевая вода</Text>
                    <Box>
                        <Heading>от 4000</Heading>
                        <Text textAlign="center">РУБ/кольцо</Text>
                    </Box>
                    <Box>
                        <Text textAlign="center">Что входит:</Text>
                        <Text>- поиск водоносного слоя;</Text>
                        <Text>- ручная копка;</Text>
                        <Text>- обезжиренные ж/б кольца;</Text>
                        <Text>- монтаж;</Text>
                        <Text>- отсыпка шахты;</Text>
                        <Text>- доставка;</Text>
                        <Text>- отсыпка фильтра.</Text>
                    </Box>
                    <Text>Скидки на заказ нескольких колодцев</Text>
                    <Text>(Консультации бесплатны)</Text>
                    <Link to={getLink("/contact")}><Button bgColor="#333333" p="14px"><Text color="#FFE44A">СВЯЗАТЬСЯ</Text></Button></Link>
                </VStack>
            </VStack>

            <Box bgColor="#A5B5C6" mx="10vw" borderRadius="6px" w="90%">
                <Box bgColor="#c9d7e5" p="20px" borderRadius="6px"><Heading size="lg">КОЛОДЦЫ от 4.000 РУБ/КОЛЬЦО</Heading></Box>
                <Box p="20px" fontFamily='Hero'>
                    <Text fontSize="20px" fontFamily='Hero'>Выполняем строительство колодцев на вашем дачном или приусадебном участке для питьевой или поливной воды в {props.city.text2 ? props.city.text2 : props.city}. Мы работаем практически в любых условиях до -25. Копаем только вручную, делаем все чисто и аккуратно, вывозим лишний грунт и
                        разравниваем. Вода чистая без жирных пятен – используем эко-кольца.</Text>
                    <Heading my="14px" size='lg'>Рытьё колодцев включает в себя:</Heading>
                    <Text>- Определение месте копки и водоносного горизонта</Text>
                    <Text>- ЖБИ кольца</Text>
                    <Text>- Завоз эко-колец ЖБИ</Text>
                    <Text>- Копка ствола колодца и монтаж колец</Text>
                    <Text>- Герметизация швов колец и их монтажных отверстий</Text>
                    <Text>- Водоопурный замок вокруг колодца/отсыпка</Text>
                </Box>
            </Box>
            <Box bgColor="#A5B5C6" mx="10vw" borderRadius="6px" w="90%">
                <Box bgColor="#c9d7e5" p="20px" borderRadius="6px"><Heading size="lg">СЕПТИКИ/ВЫГРЕБНЫЕ ЯМЫ от 14.000 РУБ/ОБЪЕКТ</Heading></Box>
                <Box p="20px" fontFamily='Hero'>
                    <Heading my="10px" size='lg'>Строительство септика включает:</Heading>
                    <Text>- Бетонные кольца (как правило 1,5 м)</Text>
                    <Text>- Механизированная копка котлована</Text>
                    <Text>- Монтаж колец</Text>
                    <Text>- Врезка основной магистрали канализации</Text>
                    <Text>- Монтаж перелива с герметизацией стыков</Text>
                    <Text>- Замазка швов жидким стеклом с ЦПР</Text>
                    <Text>- Установка крышек</Text>
                    <Text>- Отсыпка и разравнивание</Text>
                    <Text>- Консультации по уходу и эксплуатации</Text>
                </Box>
            </Box>
        </VStack>

        <VStack w='100%' p='6vh' textAlign='center'>
            <Text fontSize={20}>МЫ - ЭТО ВАШ ВЫБОР!</Text>
            <Text fontFamily='Twenty' fontSize={35} mt='10px'>НАШИ ПРЕИМУЩЕСТВА</Text>
            <Text fontSize={20} mb='80px'>Очень трудно найти ответственного подрядчика? Но вы на верном пути.</Text>

            <SimpleGrid columns={1} w='100%' justify="center" spacing="50px" pt="2vh">
                <Box border='solid 2px black' borderRadius='full' p='20px'>
                    <VStack>
                        <Image src={require('../../../img/advantages.png')} />
                        <Text fontSize="20px" fontFamily="Hero">Оговариваем с вами<br /> все детали </Text>
                    </VStack>
                </Box>
                <Box minW='14vw' border='solid 2px black' borderRadius='full' p='30px'>
                    <VStack>
                        <Image src={require('../../../img/advantages_2.png')} />
                        <Text fontSize="20px" fontFamily="Hero">Заключение договора<br /> в день обращения</Text>
                    </VStack>
                </Box>
                <Box minW='14vw' border='solid 2px black' borderRadius='full' p='30px'>
                    <VStack>
                        <Image src={require('../../../img/advantages_3.png')} />
                        <Text fontSize="20px" fontFamily="Hero">Выезжаем в день<br /> обращения </Text>
                    </VStack>
                </Box>
                <Box minW='14vw' border='solid 2px black' borderRadius='full' p='30px'>
                    <VStack>
                        <Image src={require('../../../img/advantages_4.png')} />
                        <Text fontSize="20px" fontFamily="Hero">Колодец и септик за<br /> пару дней</Text>
                    </VStack>
                </Box>
            </SimpleGrid>
        </VStack>
    </>
}