import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import React, {useState} from 'react';
import {Box, Text, Heading} from "@chakra-ui/react";

export default function Marquee(props) {
    const [cur, setCur] = useState(require("../../../img/slide4.jpg"));
    function updateBg(num) {
        if (num === 0) setCur(require("../../../img/slide4.jpg"));
        else if (num === 1) setCur(require("../../../img/Untitled-4.jpg"));
        else if (num === 2) setCur(require("../../../img/slider_1-2.jpg"));
        else if (num === 3) setCur(require("../../../img/slide-01.jpg"));
    }

    return <Box w="100%" position="relative">
        <Box position="absolute" w="100%" h="100%" bgImage={cur} bgRepeat="no-repeat" bgSize="cover" transition="background-image 0.5s ease-in-out" filter="brightness(50%)"></Box>
        <Carousel autoPlay={true} infiniteLoop={true} transitionTime={700} showStatus={false} showThumbs={false} onChange={x => updateBg(x)}>
            <Box p="200px" align="center" justify="center" w="100%">
                <Text color="white">ДЛЯ ДОМА И ДАЧИ В {props.city.text2 ? props.city.text2.toUpperCase() : props.city}</Text>
                <Heading color="white">Септики и выгребные ямы согласно СаНиП</Heading>
                <Text color="white">“Отвечаем за свою работу и даём гарантию, работаем круглый год в любых условиях.
                    Чисто, без мусора и запаха!”</Text>
            </Box>
            <Box p="200px" align="center" justify="center" w="100%">
                <Text color="white">ДЕЗИНФЕКЦИЯ И ЧИСТКА В {props.city.text2 ? props.city.text2.toUpperCase() : props.city.toUpperCase()}</Text>
                <Heading color="white">Любых питьевых и поливных колодцев</Heading>
                <Text color="white">“Используем только проверенные методы. Укрепление колец, углубление колодцев. Гарантия!”</Text>
            </Box>
            <Box p="200px" align="center" justify="center" w="100%">
                <Text color="white">Проектирование</Text>
                <Heading color="white">Проекты водоснабжения и водоотведения</Heading>
                <Text color="white">“Для частных и многоквартирных домов в {props.city.text2 ? props.city.text2 : props.city}!”</Text>
            </Box>
            <Box p="200px" align="center" justify="center" w="100%">
                <Text color="white">ДЛЯ ДОМА И ДАЧИ В {props.city.text2 ? props.city.text2.toUpperCase() : props.city.toUpperCase()}</Text>
                <Heading color="white">Септики и выгребные ямы согласно СаНиП</Heading>
                <Text color="white">“Отвечаем за свою работу и даём гарантию, работаем круглый год в любых условиях. Чисто, без мусора и запаха!”</Text>
            </Box>
        </Carousel>
    </Box>
}