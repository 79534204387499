import { Text, Box, Heading, Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export default function Article3(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return <Box w='100%' mt='150px'  >
        <Center>
            <Box w='85%' minW='1000px' maxW='1200px' >
                <Heading fontFamily='Twenty' fontSize="30px" >Поливные и питьевые колодцы в {typeof props.city === "string" ? props.city : props.city.text2}</Heading>

                <Text fontFamily='Hero' mt='20px' fontSize='20px' w='80%' >
                    Первое, с чем сталкиваются люди, приобретающие участок, – с обустройства коммуникаций. Свет, вода, канализация, газ – все это необходимо человеку для комфортного существования. Но если вы обустраиваете дачу, то вполне можно обойтись и без газа, и без света… но без воды – никуда. Поливная, питьевая – вода обязательно должна быть на любой даче, доме или стройке.<br /><br />

                    Что делать, если приобретенные сотки не относятся к городскому Водоканалу? Варианта три: постоянный подвоз воды, обустройство колодца или бурение скважины. Поговорим о колодцах.<br /><br />

                    Несмотря на техническую возможность бурения скважин, многие дачники имеют склонность к копке колодцев. Почему это происходит? Дело в том, что такой «деревенский колодец» служит не только источником воды, но и исполняет функцию аккумулирующей емкости. В самом деле, воду, выкачанную из скважины, необходимо где-то хранить, а хранение пары сотни литров жидкости на участке в шесть стандартных соток дело весьма местозатратное. В колодце же вода стоит всегда, при необходимости можно выбрать большой объем, который постепенно будет восполняться, когда необходимость спадет.<br /><br />

                    Казалось бы, колодец – простейшее сооружение, и его строительство не должно вызывать никаких затруднений. На самом же деле, нарушение норм при возведении колодца грозит получением воды с примесями, непригодной для употребления в пищу, а то и вообще уходом влаги в грунт. При использовании колодцев, построенных с нарушением санитарных норм (например, строительство колодца ближе 30 метров от выгребных ям или уличных туалетов) ваша семья рискует надолго оказаться в больнице.<br /><br />

                    Колодцы бывают трех видов: ключевые, шахтные и абиссинские (или трубчатые). Для возведения ключевого колодца необходимо, чтобы на участке находился выход на поверхность подземного источника. При залегании межпластовых вод на глубине не более 25 метров можно устроить шахтный колодец. Трубчатый колодец пробуривается (или забивается «бабкой») при неглубоком залегании грунтовых вод (около 8 м)<br /><br />

                    По материалам колодцы бывают брусовые, кирпичные, из монолитного железобетона, сборные из бетонных или полимерных колец. На сегодняшний день самое большое распространение получили колодцы из железобетонных колец за простоту и легкость монтажа, а также сравнительно небольшую стоимость. Брусовые же колодцы, наоборот, уходят в далекое прошлое не только из-за трудоемкости монтажа но и по гигиеническим характеристикам. Из чего бы вы не решили возводить ваш личный источник воды, помните: основное требование к материалам должно включать инертность по отношению к воде. Например, из кладки кирпичного колодца, при несоблюдении требований к применяемому цементу, могут вымываться соединения кальция, что нанесет ощутимый вред здоровью человека.<br /><br />

                    Устройство колодца<br /><br />

                    Самое дно возводимого вашего личного гидротехнического сооружения составляет фильтрующий слой, представляющий собой гравийную отсыпку не тоньше 10 сантиметров. Далее идет собственно ствол, представляющий собой полую трубу 1-1,5 метра в диаметре, из выбранных вами материалов. Самый верх колодца, видимую его часть, именуют оголовком. Оголовок обязательно должен быть закрытым во избежание попадания опавших листьев и гибели животных. В некоторых случаях землю возле колодца вынимают вокруг на полметра и производят обратную засыпку глиной, для предотвращения попадания стоков с поверхности.<br /><br />
                </Text>
            </Box>
        </Center>
    </Box>
}