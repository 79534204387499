import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    VStack, Box, Text, IconButton, HStack, Icon, Image
} from '@chakra-ui/react';
import "./menu.css";
import React from 'react';
import { Link } from "react-router-dom";
import { TimeIcon, HamburgerIcon } from "@chakra-ui/icons";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import CityPicker from "./cityPicker";
import Phone from '../../Phone';

export default function MenuBar(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    function getLink(target) {
        const first = window.location.pathname.slice(1).split("/")[0];
        return ["", "dw", "price", "septik", "services", "contact"].includes(first) ? `${target}` : `/${first}${target}`;
    }

    const getCurrentLink = now => window.location.pathname.slice(1).split("/").find(x => ["", "dw", "price", "septik", "services", "contact"].includes(x)) === now.slice(1) ? "mobileMenuBlockSelected" : "mobileMenuBlock";

    return <Box>
        <IconButton ref={btnRef} colorScheme='orange' onClick={onOpen} icon={<HamburgerIcon />} />
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent bgColor="gray.500" color="white" fontFamily="Hero">
                <DrawerCloseButton />
                <DrawerHeader>Меню</DrawerHeader>

                <DrawerBody>
                    <VStack justify="start">
                        <Link to={getLink("/")} className={getCurrentLink("/")} onClick={onClose}>
                            Главная
                        </Link>
                        <Link to={getLink("/price")} className={getCurrentLink("/price")} onClick={onClose}>
                            Цена
                        </Link>
                        <Link to={getLink("/dw")} className={getCurrentLink("/dw")} onClick={onClose}>
                            Копка колодцев
                        </Link>
                        <Link to={getLink("/septik")} className={getCurrentLink("/septik")} onClick={onClose}>
                            Септики
                        </Link>
                        <Link to={getLink("/services")} className={getCurrentLink("/services")} onClick={onClose}>
                            Услуги
                        </Link>
                        <Link to={getLink("/contact")} className={getCurrentLink("/contact")} onClick={onClose}>
                            Контакты
                        </Link>
                    </VStack>
                </DrawerBody>

                <DrawerFooter justifyContent="center">
                    <VStack>
                        <CityPicker city={props.city} />
                        <HStack>
                            <Icon as={FaMapMarkerAlt} style={{ color: 'gold', width: '25px', height: '25px' }} />
                            <Box>
                                <Text color="white" fontWeight={600} fontSize={18}>Адрес</Text>
                                <Text mt='0px' color='white' fontSize={17}>Проспект Королёва, 10</Text>
                            </Box>
                        </HStack>
                        <HStack>
                            <a href="tel:">
                                <Icon as={FaPhoneAlt} style={{ color: 'gold', width: '25px', height: '25px' }} />
                            </a>
                            <Box>
                                <Text color="white" fontWeight={600} fontSize={18}>Телефон</Text>
                                <Phone city={props.city} />
                            </Box>
                            <a href="https://wa.me/">
                                <Image
                                    src={require('../../../img/whatsapp.svg').default}
                                    w='30px'
                                    h='30px'
                                />
                            </a>
                        </HStack>
                        <Box>
                            <TimeIcon color='gold' w='30px' h='30px' float='left' mt={3} />
                            <Box>
                                <Text color="white" fontWeight={600} fontSize={18} ml={10}>Время работы</Text>
                                <Text mt='0px' color='white' fontSize={17} ml={10}>ПН-ВС с 9:00 до 20:00</Text>
                            </Box>
                        </Box>
                    </VStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </Box>
}