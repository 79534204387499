import React from 'react';
import SeptikBlock from "../Septik";
import CatalogSeptik1 from "../Catalogs/septik1";
import SeptikBiofor from "../Catalogs/septikBiofor";
import SeptikEcstra from "../Catalogs/septikEcstra";
import SeptikStandart from "../Catalogs/septikStandart";
import { Fade } from 'react-reveal';
import {
    Flex, Text, Heading, VStack, Box, Image, Radio, RadioGroup, HStack, Input, InputGroup, InputLeftAddon, Button, useToast, Center, List, ListItem, SimpleGrid
} from '@chakra-ui/react';
import Services from "../ourServices";
import FormApplication from "../Form";
import Marquee from "../Marquee";
import Infos from "../Infos";
import UsefulInformation from "../UsefulInformation";
import axios from "axios";

export default function Main(props) {
    const [size, setSize] = React.useState("2");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const toast = useToast();


    const handleSend = async () => {
        // console.log(size, name, phone);

        if (name.length < 2 || phone.length < 10) toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        }); else {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            await axios.post('https://api.twodev.cc/helper/septiki', {
                city: props.city.text ? props.city.text : props.city,
                info: `Главная страница; 1я форма; ${size} человек`,
                name: name,
                phone: phone
            });
        }
    };

    return <>
        <Image src={require('../../../img/back.png')} style={{ paddingTop: "10%", position: "fixed", zIndex: -1000, bgSize: "cover", scale: "4", filter: "brightness(30%)" }} />
        <VStack w="100%" pt="10vh" px="10vw" spacing="60px">
            <VStack w="100%" spacing="14px">
                <Heading color="white" align="center">Септики из бетонных колец для дома и дачи. Продажа и установка под ключ в {props.city.text2 ? props.city.text2 : props.city}</Heading>
                <Text color="white" fontSize="10pt">От 11 000 руб за комплект</Text>
                <Text color="white" fontSize="10pt">Монтаж любой сложности с гарантией</Text>
                <Text color="white" fontSize="10pt">Профессиональный подбор септиков</Text>
            </VStack>
            <Box border="6px solid white" bgColor="#363636" p="18px" align="center">
                <Heading fontSize="16px" color="white">РАСЧЁТ СТОИМОСТИ</Heading>
                <Text fontSize="14px" color="#999999" my="14px">Введите параметры и получите расчёт за 3 минуты</Text>
                <RadioGroup onChange={setSize} value={size} align="center" isRequired={true} defaultValue='2'>
                    <HStack justify="center" spacing="50px">
                        <VStack>
                            <Radio size='sm' value='2' colorScheme='orange'><Text color='white'>2 чел - 1.8
                                м³</Text></Radio>
                            <Radio size='sm' value='3' colorScheme='orange'><Text color='white'>3 чел - 1.8
                                м³</Text></Radio>
                            <Radio size='sm' value='4' colorScheme='orange'><Text color='white'>4 чел - 2.4
                                м³</Text></Radio>
                        </VStack>
                        <VStack>
                            <Radio size='sm' value='5' colorScheme='orange'><Text color='white'>5 чел - 4.5
                                м³</Text></Radio>
                            <Radio size='sm' value='6' colorScheme='orange'><Text color='white'>6 чел - 6.8
                                м³</Text></Radio>
                            <Radio size='sm' value='7' colorScheme='orange'><Text color='white'>7+</Text></Radio>
                        </VStack>
                    </HStack>
                </RadioGroup>
                <Input my="16px" onChange={event => setName(event.target.value)} placeholder='Ваше имя' color='white' />
                <InputGroup>
                    <InputLeftAddon children='+7' />
                    <Input type='tel' onChange={event => setPhone(event.target.value)} placeholder='Ваш телефон'
                        color='white' />
                </InputGroup>
                <Button colorScheme='orange' my="16px" onClick={async () => { await handleSend(); }}>Рассчитать</Button>
                <Text color="#b3b1b1" fontSize="10px">Отправляя данные, Вы даёте согласие на обработку персональных
                    данных</Text>
            </Box>
        </VStack>

        <VStack w="100%" bg='white' mt="10vh" p='30px' spacing="20vh">
            <SeptikBlock city={props.city} />
            <CatalogSeptik1 city={props.city} />
            <SeptikBiofor city={props.city} />
            <SeptikEcstra city={props.city} />
            <SeptikStandart city={props.city} />
        </VStack>

        <VStack w='100%' bg='white' px="8vw">
            <Fade bottom>
                <Box textAlign='center' mt='100px' pb='70px'>
                    <Text fontWeight={600} fontSize={17}>КОПКА КОЛОДЦЕВ ПОД ЧИСТУЮ ВОДУ, СЕПТИКИ ПОД "КЛЮЧ" В {props.city.text2 ? props.city.text2.toUpperCase() : props.city.toUpperCase()}</Text>
                    <Text fontFamily='Twenty' fontSize={45} mt='10px'>Мы и наши услуги</Text>
                    <i><Text сolor='gray' fontSize={14}>Колодцы под питьевую воду от 4500 руб в {props.city.text2 ? props.city.text2 : props.city},
                        ремонт, углубление, чистка колодезных шахт на частных участках "под ключ".</Text></i>

                    <Text fontSize={17} mt='45px'>Наша компания продолжительное время занимается
                        строительством, ремонтом и углублением колодцев в любое время года и не только в&#160;{props.city.text2 ? props.city.text2 : props.city} но и по области. Если вам необходима чистка колодца под питьевую воду или
                        на даче для полива, то мы вам в этом поможем. Копка и углубление колодцев в &#160;{props.city.text2 ? props.city.text2 : props.city}
                        - мы выполняем даже зимой в любую погоду! Чистку питьевых колодцев проводим специальной
                        машиной под высоким давлением. Используем бетонные кольца изготовленные по специальной
                        технологии, которые не оставляют масленной и жировой пленки на поверхности воды. Также
                        выполняем строительство септиков и выгребных ям под ключ для дачи и дома для постоянного
                        и временного проживания от 1 человека. Чистка и ремонт любых типов переливных септиков и
                        выгребных ям, увеличиваем их объём, устанавливаем дополнительные камеры при высоких
                        грунтовых водах, <span
                            style={{ color: "gold" }}>промывка гидравлическим ударом.</span></Text>

                    {/*<Services city={props.city} />*/}
                </Box>
            </Fade>
            {/*<Marquee city={props.city} />*/}
            <VStack w='100%' py='6vh' textAlign='center'>
                <Text fontSize={20}>МЫ - ЭТО ВАШ ВЫБОР!</Text>
                <Text fontFamily='Twenty' fontSize={35} mt='10px'>НАШИ ПРЕИМУЩЕСТВА</Text>
                <Text fontSize={20} mb='80px'>Очень трудно найти ответственного подрядчика? Но вы на верном пути.</Text>

                <SimpleGrid columns={1} w='100%' justify="center" spacing="50px" pt="2vh">
                    <Box border='solid 2px black' borderRadius='full' p='20px'>
                        <VStack>
                            <Image src={require('../../../img/advantages.png')} />
                            <Text fontSize="20px" fontFamily="Hero">Оговариваем с вами<br /> все детали </Text>
                        </VStack>
                    </Box>
                    <Box minW='14vw' border='solid 2px black' borderRadius='full' p='30px'>
                        <VStack>
                            <Image src={require('../../../img/advantages_2.png')} />
                            <Text fontSize="20px" fontFamily="Hero">Заключение договора<br /> в день обращения</Text>
                        </VStack>
                    </Box>
                    <Box minW='14vw' border='solid 2px black' borderRadius='full' p='30px'>
                        <VStack>
                            <Image src={require('../../../img/advantages_3.png')} />
                            <Text fontSize="20px" fontFamily="Hero">Выезжаем в день<br /> обращения </Text>
                        </VStack>
                    </Box>
                    <Box minW='14vw' border='solid 2px black' borderRadius='full' p='30px'>
                        <VStack>
                            <Image src={require('../../../img/advantages_4.png')} />
                            <Text fontSize="20px" fontFamily="Hero">Колодец и септик за<br /> пару дней</Text>
                        </VStack>
                    </Box>
                </SimpleGrid>
            </VStack>

            <VStack
                bgImage={require('../../../img/useful.jpeg')}
                bgSize='cover'
                w='119%'
                h='max-content'
                p="20px"
                spacing="20px"
            >
                <Text fontSize={30} fontFamily='Hero' align="center">Полезная информация</Text>
                <UsefulInformation />
            </VStack>
            <Box>
                <VStack w="100%"  my='50px' bg='white' p="30px" borderRadius={20} boxShadow="0 10px 10px rgba(0, 0, 0, 0.25), 1px 14px 7px 7px rgba(0, 0, 0, 0.22)">
                    <Text fontFamily='Hero' fontSize={20} textAlign='center'>отзывы</Text>
                    <Text fontFamily='Twenty' fontSize={22} textAlign='center'>ЧТО ГОВОРЯТ О НАС НАШИ
                        КЛИЕНТЫ?</Text>
                    <i><Text fontFamily='Hero' fontSize={17} textAlign='center' mb='20px'>Мы собрали тут только
                        самую маленькую часть отзывов о нашей работе.</Text></i>
                    <VStack spacing={25} fontFamily='Hero'>
                    <Box w='100%' border='solid 0.5px gray' opacity='0.5'></Box>
                        <Text align="center">
                            Все менеджеры чательно контролирует процесс копки колодца и консультирует по всем вопросам чётко и грамотно. На даче у нас вечная проблема, но теперь у меня есть колодец с кристально чистой водой
                        </Text>
                        <Box w='100%' border='solid 0.5px gray' opacity='0.5'></Box>
                        <Text align="center">
                            Отличная работа и главное чистота. Септик в загородном доме отлично работает уже 3 год. Чистил всего пару раз.
                        </Text>
                        <Box w='100%' border='solid 0.5px gray' opacity='0.5'></Box>
                        <Text align="center">
                            Заказывал погреб и двухкамерный септик в компании "Колодец-Септик", и я полностью доволен. Отдельное спасибо хочется сказать за быстрые сроки и качественное исполение. Парни мастера своего дела и это полностью оправдывает все потраченые деньги.
                        </Text>
                        <Image src={require('../../../img/reviews.png')} w='460px' />
                    </VStack>
                </VStack>
            </Box>
            <Box w='100%' h='500px' >
                <Center>
                    <FormApplication city={props.city} />
                </Center>
            </Box>
            {/*<Infos city={props.city} />*/}
        </VStack>
    </>
}