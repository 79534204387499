import {
    Box,
    Center,
    GridItem,
    SimpleGrid,
    Image,
    Text
} from "@chakra-ui/react";
import AppModal from "../AppModal";

export default function CatalogSeptik1(props) {
    return <Box w='100%' mt={props.mt} >
        <Center>
            <SimpleGrid columns={4} gap='20px' >
                <GridItem>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik1.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero' >Комплект 1</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик однокамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 2 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, крышка, усиленный полимерный люк.</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty" >11 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Комплект 1" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik2.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'  >Комплект 2</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик однокамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 3 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, крышка, усиленный полимерный люк.</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty">13 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Комплект 2" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik3.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>Комплект 3</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик однокамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 4 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, крышка, усиленный полимерный люк.</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty" >16 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Комплект 3" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik4.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero' >Комплект 4</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик двухкамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 4 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, 2 крышки, 2 усиленных полимерных люка.</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty">20 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Комплект 4" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem mt='15px' >
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik5.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>Комплект 5</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик двухкамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 5 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, 2 крышки, 2 усиленных полимерных люка</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty">22 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Комплект 5" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem mt='15px'>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik6.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>Комплект 6</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик двухкамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 6 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, 2 крышки, 2 усиленных полимерных люка</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty">23 500 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Комплект 6" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem mt='15px'>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik7.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>Макси</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик двухкамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 8 шт/⌀ 1 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'> В комплекте: дно, 2 крышки, 2 усиленных полимерных люка</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty">27 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="Макси" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem mt='15px'>
                    <Box w='290px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                        <Box h='190px' w='100%'>
                            <Image src={require('../../../img/septik8.png')} />
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={2}>
                            <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>К-1</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                            <Center>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#000000',
                                    height: 0.6,
                                    borderColor: '#000000',
                                    width: 70,
                                    opacity: 0.4
                                }} />
                            </Center>
                        </Box>
                        <Box h='50px' bg='rgb(235,235,235)' p={3} >
                            <Text fontSize={20} fontFamily='Hero'>Септик однокамерный</Text>
                        </Box>
                        <Box h='50px' bg='rgb(245,245,245)' p={3}>
                            <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 2шт/1,5 м</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' p={1}>
                            <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, крышка, усиленный полимерный люк</Text>
                        </Box>
                        <Box h='80px' bg='rgb(245,245,245)' p={3} >
                            <Text fontSize={45} fontFamily="Twenty">15 000 ₽</Text>
                        </Box>
                        <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                            <AppModal septik="К-1" city={props.city} />
                        </Box>
                        <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                            <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                        </Box>
                    </Box>
                </GridItem>
            </SimpleGrid>
        </Center>



        <Box w='100%' mt='90px' >
            <Center>
                <SimpleGrid columns={3} gap='25px' >
                    <GridItem>
                        <Box w='390px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                            <Box h='190px' w='100%'>
                                <Center>
                                    <Image src={require('../../../img/septik9.png')} h='220px' />
                                </Center>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' p={2}>
                                <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>К-2</Text>
                            </Box>
                            <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                <Center>
                                    <hr style={{
                                        color: '#000000',
                                        backgroundColor: '#000000',
                                        height: 0.6,
                                        borderColor: '#000000',
                                        width: 70,
                                        opacity: 0.4
                                    }} />
                                </Center>
                            </Box>
                            <Box h='50px' bg='rgb(235,235,235)' p={3} >
                                <Text fontSize={20} fontFamily='Hero'>Септик двухкамерный</Text>
                            </Box>
                            <Box h='50px' bg='rgb(245,245,245)' p={3}>
                                <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 4шт/1,5м</Text>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' p={1}>
                                <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, 2 крышки, 2 доборных кольца, 2 усиленных полимерных люка.</Text>
                            </Box>
                            <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                <Text fontSize={45} fontFamily="Twenty">30 000 ₽</Text>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                <AppModal septik="К-2" city={props.city} />
                            </Box>
                            <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box w='390px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                            <Box h='190px' w='100%'>
                                <Center>
                                    <Image src={require('../../../img/septik10.png')} h='210px' />
                                </Center>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' p={2}>
                                <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>К-3</Text>
                            </Box>
                            <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                <Center>
                                    <hr style={{
                                        color: '#000000',
                                        backgroundColor: '#000000',
                                        height: 0.6,
                                        borderColor: '#000000',
                                        width: 70,
                                        opacity: 0.4
                                    }} />
                                </Center>
                            </Box>
                            <Box h='50px' bg='rgb(235,235,235)' p={3} >
                                <Text fontSize={20} fontFamily='Hero'>Септик двухкамерный</Text>
                            </Box>
                            <Box h='50px' bg='rgb(245,245,245)' p={3}>
                                <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 6 шт/1,5 м</Text>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' p={1}>
                                <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, 2 крышки,
                                    2 усиленных полимерных люка</Text>
                            </Box>
                            <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                <Text fontSize={45} fontFamily="Twenty">33 000 ₽</Text>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                <AppModal septik="К-3" city={props.city} />
                            </Box>
                            <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box w='390px' h='663px' border='solid 1px rgb(245,245,245)' borderBottom='solid 2px rgb(205,205,205)' textAlign='center' >
                            <Box h='190px' w='100%'>
                                <Image src={require('../../../img/septik11.png')} />
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' p={2}>
                                <Text textAlign='center' fontSize='22pt' fontWeight={400} fontFamily='Hero'>К-Макси</Text>
                            </Box>
                            <Box h='50px' bg='rgb(245,245,245)' pt='25px'>
                                <Center>
                                    <hr style={{
                                        color: '#000000',
                                        backgroundColor: '#000000',
                                        height: 0.6,
                                        borderColor: '#000000',
                                        width: 70,
                                        opacity: 0.4
                                    }} />
                                </Center>
                            </Box>
                            <Box h='50px' bg='rgb(235,235,235)' p={3} >
                                <Text fontSize={20} fontFamily='Hero'>Септик трёхкамерный</Text>
                            </Box>
                            <Box h='50px' bg='rgb(245,245,245)' p={3}>
                                <Text fontSize={18} fontFamily='Hero'>Бетонных колец - 9шт/1,5 м</Text>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' p={1}>
                                <Text fontSize={18} fontFamily='Hero'>В комплекте: дно, 3 крышки,
                                    3 усиленных полимерных люка</Text>
                            </Box>
                            <Box h='80px' bg='rgb(245,245,245)' p={3} >
                                <Text fontSize={45} fontFamily="Twenty">51 000 ₽</Text>
                            </Box>
                            <Box h='60px' bg='rgb(235,235,235)' pt={2.5} >
                                <AppModal septik="К-Макси" city={props.city} />
                            </Box>
                            <Box h='60px' bg='rgb(245,245,245)' pt={2} >
                                <Text fontSize={16} fontFamily='Hero'>*Доставка и монтаж не входит, уточняйте у менеджера</Text>
                            </Box>
                        </Box>
                    </GridItem>
                </SimpleGrid>
            </Center>

        </Box>

    </Box>
}