import { HStack } from "@chakra-ui/react";
import "./menu.css";
import { Link } from "react-router-dom";

export default function MenuBar() {
    function getLink(target) {
        const first = window.location.pathname.slice(1).split("/")[0];
        return ["", "dw", "price", "septik", "services", "contact"].includes(first) ? `${target}` : `/${first}${target}`;
    }

    const getCurrentLink = now => window.location.pathname.slice(1).split("/").find(x => ["", "dw", "price", "septik", "services", "contact"].includes(x)) === now.slice(1) ? "menuBlockSelected" : "menuBlock";

    return <HStack justify="center" bg="white" w="100%" spacing="14px">
        <Link to={getLink("/")} className={getCurrentLink("/")}>
            Главная
        </Link>
        <Link to={getLink("/price")} className={getCurrentLink("/price")}>
            Цена
        </Link>
        <Link to={getLink("/dw")} className={getCurrentLink("/dw")}>
            Копка колодцев
        </Link>
        <Link to={getLink("/septik")} className={getCurrentLink("/septik")}>
            Септики
        </Link>
        <Link to={getLink("/services")} className={getCurrentLink("/services")}>
            Услуги
        </Link>
        <Link to={getLink("/contact")} className={getCurrentLink("/contact")}>
            Контакты
        </Link>
    </HStack>
}